///<reference path='../min.references.ts' />
var home;
(function (home) {
    'use strict';
    /* @ngdoc object
    * @name home
    * @description
    *
    */
    angular.module('home', [
        'ngRoute',
        'ngStorage',
        'ngCookies',
        'infinite-scroll'
    ]);
})(home || (home = {}));

///<reference path='../../min.references.ts' />
var convertIdToProperty;
(function (_convertIdToProperty) {
    'use strict';
    /**
    * @ngdoc filter
    * @name home.filter:convertIdToProperty
    *
    * @description
    *
    * @param {String} input The id to filter
    * @param {Array} input The tags
    * @param {String} input The tag namespace
    * @param {String} input The tag property
    * @returns {String} The filtered id
    *
    */
    angular.module('home').filter('convertIdToProperty', convertIdToProperty);
    function convertIdToProperty() {
        return function (id, tags, namespace, property) {
            var propName = property ? property : 'name';
            var namespaceId = 1;
            switch (namespace) {
                case 'activities':
                    namespaceId = 0;
                    break;
                case 'countries':
                    namespaceId = 1;
                    break;
                case 'regions':
                    namespaceId = 2;
                    break;
            }
            if (tags && tags[namespaceId]) {
                for (var i = 0; i < tags[namespaceId].length; i++) {
                    if (id === tags[namespaceId][i].id) {
                        return tags[namespaceId][i][propName];
                    }
                }
            }
            return id;
        };
    }
})(convertIdToProperty || (convertIdToProperty = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var UserhomeCtrl;
    (function (_UserhomeCtrl) {
        'use strict';
        var UserhomeCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function UserhomeCtrl(User, $rootScope, $location, $log, CartService, Logger) {
                this.User = User;
                this.$rootScope = $rootScope;
                this.$location = $location;
                this.$log = $log;
                this.CartService = CartService;
                this.Logger = Logger;
                var vm = this;
                vm.ctrlName = 'UserhomeCtrl';
                vm.user = null;
                vm.allUsers = [];
                vm.deleteUser = deleteUser;
                vm.admin = false;
                vm.allOrders = [];
                vm.userOrder = [];
                vm.getOrdersByUsername = getOrdersByUsername;
                vm.markOrderProcessed = markOrderProcessed;
                initController();
                function initController() {
                    if (!$rootScope.globals || !$rootScope.globals.currentUser) {
                        return $location.path('/login');
                    }
                    loadCurrentUser();
                }
                function loadCurrentUser() {
                    User.getByUsername($rootScope.globals.currentUser.username).then(function (user) {
                        vm.user = user.data;
                        $log.debug("loadCurrentUser " + vm.user.username);
                        getOrdersByUsername(vm.user.username);
                    });
                    isAdminUser();
                }
                function loadAllUsers() {
                    if (vm.admin) {
                        User.getAll().then(function (users) {
                            vm.allUsers = users.data;
                            loadAllOrders();
                        });
                    }
                }
                function deleteUser(id) {
                    if (vm.admin) {
                        User.delete(id).then(function (response) {
                            if (response.data) {
                                Logger.logSuccess('Der User wurde gelöscht', 'empty', this, true);
                            }
                            else {
                                Logger.logError(response.message, 'empty', this, true);
                            }
                        });
                    }
                }
                function isAdminUser() {
                    $log.debug("isAdminUser " + $rootScope.globals.currentUser.username);
                    User.isAdmin($rootScope.globals.currentUser.username).then(function (response) {
                        if (response.data) {
                            $log.debug("isAdminUser: " + response.data);
                            vm.admin = true;
                            loadAllUsers();
                        }
                        else {
                            $log.debug("noAdminUser!");
                            vm.admin = false;
                        }
                    });
                }
                function loadAllOrders() {
                    if (vm.admin) {
                        CartService.getAllOrders().then(function (orders) {
                            $log.debug("cart-controller:loadAllOrders");
                            vm.allOrders.push(orders.data);
                        });
                    }
                }
                function getOrdersByUsername(username) {
                    CartService.getOrdersByUsername(username).then(function (orders) {
                        $log.debug("cart-controller:getOrdersByUsername for " + username);
                        vm.userOrder.push(orders.data);
                    });
                }
                function markOrderProcessed(ordered) {
                    if (vm.admin) {
                        CartService.markOrderProcessed(ordered).then(function (response) {
                            if (response.data) {
                                Logger.logSuccess('Die Bestellung wurde als verarbeitet markiert.', 'empty', this, true);
                            }
                            else {
                                Logger.logError(response.message, 'empty', this, true);
                            }
                        });
                    }
                }
            }
            // $inject annotation.
            // It provides $injector with information about dependencies to be injected into constructor
            // it is better to have it close to the constructor, because the parameters must match in count and type.
            // See http://docs.angularjs.org/guide/di
            UserhomeCtrl.$inject = [
                'User',
                '$rootScope',
                '$location',
                '$log',
                'CartService',
                'Logger'
            ];
            return UserhomeCtrl;
        })();
        /**
        * @ngdoc object
        * @name home.controller:UserhomeCtrl
        *
        * @description
        *
        */
        angular.module('home').controller('UserhomeCtrl', UserhomeCtrl);
    })(UserhomeCtrl = Home.UserhomeCtrl || (Home.UserhomeCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var RegisterCtrl;
    (function (_RegisterCtrl) {
        'use strict';
        var RegisterCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function RegisterCtrl(User, $location, $rootScope, logger) {
                this.User = User;
                this.$location = $location;
                this.$rootScope = $rootScope;
                this.logger = logger;
                var vm = this;
                vm.ctrlName = 'RegisterCtrl';
                vm.register = register;
                function register() {
                    vm.dataLoading = true;
                    User.create(vm.user).then(function (response) {
                        if (response.data) {
                            logger.logSuccess('Registration erfolgreich, bitte einloggen', 'empty', this, true);
                            $location.path('/login');
                        }
                        else {
                            logger.logError(response.message, 'empty', this, true);
                            vm.dataLoading = false;
                        }
                    });
                }
            }
            // $inject annotation.
            // It provides $injector with information about dependencies to be injected into constructor
            // it is better to have it close to the constructor, because the parameters must match in count and type.
            // See http://docs.angularjs.org/guide/di
            RegisterCtrl.$inject = [
                'User',
                '$location',
                '$rootScope',
                'Logger'
            ];
            return RegisterCtrl;
        })();
        /**
        * @ngdoc object
        * @name home.controller:RegisterCtrl
        *
        * @description
        *
        */
        angular.module('home').controller('RegisterCtrl', RegisterCtrl);
    })(RegisterCtrl = Home.RegisterCtrl || (Home.RegisterCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var LoginCtrl;
    (function (_LoginCtrl) {
        'use strict';
        var LoginCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function LoginCtrl($location, Authentication, $log, logger) {
                this.$location = $location;
                this.Authentication = Authentication;
                this.$log = $log;
                this.logger = logger;
                var vm = this;
                vm.ctrlName = 'LoginCtrl';
                vm.login = login;
                // reset login status
                Authentication.clearCredentials();
                function login() {
                    vm.dataLoading = true;
                    Authentication.login(vm.username, vm.password, function (response) {
                        if (response.success) {
                            Authentication.setCredentials(vm.username, response.token);
                            $location.path('/userhome');
                            $log.debug("Login erfolgreich für username " + vm.username);
                        }
                        else {
                            $log.debug(response.message + " " + vm.username);
                            logger.logError(response.message, 'empty', this, true);
                            vm.dataLoading = false;
                        }
                    });
                }
            }
            // $inject annotation.
            // It provides $injector with information about dependencies to be injected into constructor
            // it is better to have it close to the constructor, because the parameters must match in count and type.
            // See http://docs.angularjs.org/guide/di
            LoginCtrl.$inject = [
                '$location',
                'Authentication',
                '$log',
                'Logger'
            ];
            return LoginCtrl;
        })();
        /**
        * @ngdoc object
        * @name home.controller:LoginCtrl
        *
        * @description
        *
        */
        angular.module('home').controller('LoginCtrl', LoginCtrl);
    })(LoginCtrl = Home.LoginCtrl || (Home.LoginCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var IndexCtrl;
    (function (_IndexCtrl) {
        'use strict';
        var IndexCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function IndexCtrl($window, $filter, $scope, $log, $location, $routeParams, $http, _serverUrl_, repository, tagging) {
                this.$window = $window;
                this.$filter = $filter;
                this.$scope = $scope;
                this.$log = $log;
                this.$location = $location;
                this.$routeParams = $routeParams;
                this.$http = $http;
                this._serverUrl_ = _serverUrl_;
                this.repository = repository;
                this.tagging = tagging;
                // redirect page with .html extension to angular page
                if ($window.location.href.indexOf(".html") > 0) {
                    $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
                }
                var vm = this;
                vm.ctrlName = 'IndexCtrl';
                //$log.debug("ctrlName: " + vm.ctrlName);
                vm.serverUrl = _serverUrl_;
                vm.galleries = vm.repository.getGalleries();
                vm.galleries = $filter('orderBy')(vm.galleries, '-date');
                vm.themeFilter = vm.$location.path();
                vm.hasInfiniteScroll = vm.$location.path().indexOf('/index') === 0;
                // title for travel collections
                vm.title = vm.getTitle(vm.themeFilter);
                vm.ogImage = vm.getOgImage(vm.themeFilter);
                vm.canonicalUrl = vm.serverUrl.host + vm.themeFilter;
                vm.country = $routeParams.countryId;
                vm.region = $routeParams.regionId;
                vm.activity = $routeParams.activityId;
                vm.tags = vm.tagging.getTags();
                vm.countryTags = vm.tagging.getCountryTags();
                vm.regionTags = vm.tagging.getRegionTags();
                vm.activityTags = vm.tagging.getActivityTags();
                // change sort order for travel indices
                vm.orderby = vm.themeFilter.indexOf('/exped/') < 0 ? '-date' : 'date';
                // generate meta tags from route params
                var country = vm.country ? $filter('convertIdToProperty')(vm.country, vm.tags, 'countries', 'name') : "";
                var region = vm.region ? $filter('convertIdToProperty')(vm.region, vm.tags, 'regions', 'name') : "";
                var activity = vm.activity ? $filter('convertIdToProperty')(vm.activity, vm.tags, 'activities', 'name') : "";
                vm.addBreadcrumb = "[";
                if (country) {
                    vm.metaTitle = "bergauf.ch Bildergalerien nach Land: " + country;
                    vm.metaDescription = "Suche nach Land " + country + " in den Bildergalerien im Outdoor Photo-Blog";
                    vm.addBreadcrumb += '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": [' + '\n{"@type": "ListItem",\n"position": 1,\n"item":"https://www.bergauf.ch/suche",\n"name": "Alle Bildergalerien"},\n' + '\n{"@type": "ListItem",\n"position": 2,\n"name": "' + country + '"}\n' + '\]' + '' + '\n}';
                }
                else if (region) {
                    vm.metaTitle = "bergauf.ch Bildergalerien nach Region: " + region;
                    vm.metaDescription = "Suche nach Region " + region + " in den Bildergalerien im Outdoor Photo-Blog";
                    vm.addBreadcrumb += '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": [' + '\n{"@type": "ListItem",\n"position": 1,\n"item":"https://www.bergauf.ch/suche",\n"name": "Alle Bildergalerien"},\n' + '\n{"@type": "ListItem",\n"position": 2,\n"name": "' + region + '"}\n' + '\]' + '' + '\n}';
                }
                else if (activity) {
                    vm.metaTitle = "bergauf.ch Bildergalerien nach Aktivität: " + activity;
                    vm.metaDescription = "Tourensuche nach Aktivität " + activity + " im Outdoor Photo-Blog";
                    vm.addBreadcrumb += '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": [' + '\n{"@type": "ListItem",\n"position": 1,\n"item":"https://www.bergauf.ch/suche",\n"name": "Alle Bildergalerien"},\n' + '\n{"@type": "ListItem",\n"position": 2,\n"name": "' + activity + '"}\n' + '\]' + '' + '\n}';
                }
                else {
                    vm.metaTitle = "Willkommen beim Outdoor Fotoblog bergauf.ch";
                    vm.metaDescription = "Outdoor Photo-Blog und Gipfelbuch zu den Themen Skitouren, Bergsteigen, MTB und Reisen";
                    vm.addBreadcrumb += '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": [' + '\n{"@type": "ListItem",\n"position": 1,\n"name": "' + vm.title + '"}\n' + '\]' + '' + '\n}';
                }
                vm.addBreadcrumb += "\n]";
                // ToDo: Do not reset on each location change ?
                vm.items = vm.galleries.slice(0, 50);
                vm.addMoreItems = function () {
                    if (vm.items.length < vm.galleries.length) {
                        vm.items = vm.galleries.slice(0, vm.items.length + 50);
                        console.log('addMoreItems called. items: ' + vm.items.length);
                    }
                };
                vm.triggerScroll = function () {
                    for (var i = 0; i < 11; i++) {
                        $scope.$emit('list:filtered');
                    }
                    //console.log('triggerScroll called. items: ' + vm.items.length);
                };
            }
            // ToDo: content shouldn't be in a controller
            IndexCtrl.prototype.getTitle = function (path) {
                var title;
                switch (path) {
                    case '/bergtouren':
                        title = 'Bergtouren';
                        break;
                    case '/skitouren':
                        title = 'Skitouren';
                        break;
                    case '/exped/gravelpacking-piemont':
                        title = 'Gravelpacking Piemont - Vom Simplonpass an die Ligurische Küste';
                        break;
                    case '/exped/gravelpacking-schweiz':
                        title = 'Gravelpacking Schweiz - 7 von 20 Etappen, inkl. Abstecher nach Italien';
                        break;
                    case '/exped/reise-nach-marokko':
                        title = 'Marokko - Highlights im Süden';
                        break;
                    case '/exped/reise-nach-kambodscha':
                        title = 'Kambodscha - Rundreise';
                        break;
                    case '/exped/reise-nach-kolumbien':
                        title = 'Kolumbien - In 4.5 Wochen das Land entdecken';
                        break;
                    case '/exped/reise-nach-sulawesi':
                        title = 'Sulawesi - In 4 Wochen von Süden nach Norden';
                        break;
                    case '/exped/reise-nach-island':
                        title = 'Island - Einmal rund um die Vulkaninsel';
                        break;
                    case '/exped/reise-nach-cabo-verde':
                        title = 'Kapverden - Inseln São Vicente, Santo Antão und Santiago';
                        break;
                    case '/exped/reise-nach-kuba':
                        title = 'Kuba - Unterwegs in Kuba';
                        break;
                    case '/exped/reise-nach-myanmar':
                        title = 'Burma - eine Reise durch Myanmar';
                        break;
                    case '/exped/oman':
                        title = 'Oman';
                        break;
                    case '/exped/skitouren-im-iran':
                        title = "Iran - Skitouren im Zardkouh- und Alborz-Gebirge";
                        break;
                    case '/exped/eastgreenland06':
                        title = "Ost-Grönland - Karale Ski-Haute Route (Bergwelt)";
                        break;
                    case '/exped/maroc04':
                        title = "Marokko - Skitouren im Hohen Atlas";
                        break;
                    case '/exped/mtb-reise-ladakh':
                        title = 'Ladakh - MTB-Tour durch das kleine Tibet (bike adventure tours Bikereise)';
                        break;
                    case '/exped/altiplano':
                        title = 'Altiplano Argentina - Bikeabenteuer in Nordargentinien (bike adventure tours Bikereise)';
                        break;
                    case '/exped':
                        title = 'Reisen';
                        break;
                    case '/panoramas':
                        title = 'Panoramas';
                        break;
                    default:
                        title = 'Suche';
                }
                return title;
            };
            // ToDo: content shouldn't be in a controller
            IndexCtrl.prototype.getOgImage = function (path) {
                var ogImage;
                switch (path) {
                    case '/index':
                        ogImage = '/data/skitouren/undri-triftlimi/skitour-trifthuette-undri-triftlimi-realp-bild-17.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/reise':
                        ogImage = '/data/exped/reise-nach-island/sueden-vik-reykjanes/sueden-vik-reykjanes-bild-6.jpg';
                        break;
                    case '/exped/reise-nach-marrokko':
                        ogImage = '/data/exped/reise-nach-marokko/marrakesch/marrakesch-sightseeing-bild-8.webp';
                        break;
                    case '/exped/reise-nach-sulawesi':
                        ogImage = '/data/exped/reise-nach-sulawesi/bahia-tomini-malenge/bahia-tomini-togian-islands-bild-18.jpg';
                        break;
                    case '/exped/reise-nach-island':
                        ogImage = '/data/exped/reise-nach-island/norden-godafoss-husavik/norden-godafoss-husavik-bild-23.jpg';
                        break;
                    case '/exped/reise-nach-cabo-verde':
                        ogImage = '/data/exped/reise-nach-cabo-verde/fontainhas-santo-antao/fontainhas-santo-antao-kapverden-bild-16.jpg';
                        break;
                    case '/exped/reise-nach-kuba':
                        ogImage = '/data/exped/reise-nach-kuba/havanna/kubareise-havanna-bild-23.jpg';
                        break;
                    case '/exped/reise-nach-myanmar':
                        ogImage = '/data/exped/reise-nach-myanmar/namshan/burmareise-namhsan_31.jpg';
                        break;
                    case '/exped/oman':
                        ogImage = '/data/exped/oman/wahiba/0782.jpg';
                        break;
                    case '/exped/skitouren-im-iran':
                        ogImage = "/data/exped/skitouren-im-iran/damavand_highcamp/skitour-goosfandsara-bargah-sevom-bild-25.jpg";
                        break;
                    case '/exped/eastgreenland06':
                        ogImage = "/data/exped/eastgreenland06/060505/1702.jpg";
                        break;
                    case '/exped/maroc04':
                        ogImage = "/data/exped/maroc04/040302/cd1_253.jpg";
                        break;
                    case '/exped/mtb-reise-ladakh':
                        ogImage = '/data/exped/mtb-reise-ladakh/baralachala/mtb-tour-baralacha-la-pass_18.jpg';
                        break;
                    case '/exped/altiplano':
                        ogImage = '/data/exped/altiplano/salinasgrandes/0357.jpg';
                        break;
                    case '/panoramas':
                        ogImage = '';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/skitour':
                        ogImage = '/data/skitouren/undri-triftlimi/skitour-trifthuette-undri-triftlimi-realp-bild-17.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/bergtour':
                        ogImage = '/data/bergtouren/silvrettahorn/hochtour-alp-sardasca-silvrettahorn-bild-8.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/hochtour':
                        ogImage = '/data/bergtouren/silvrettahorn/hochtour-alp-sardasca-silvrettahorn-bild-8.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/alpinwanderung':
                        ogImage = '/data/bergtouren/engelberger-rotstock/alpine-wanderung-engelberger-rotstock-bild-4.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/bike-and-hike':
                        ogImage = '/data/bergtouren/silberen/bergwanderung-silberen-3.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/mtb':
                        ogImage = '/data/velotouren/mtb-walliser-haute-route/mtb-walliser-haute-route-95.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/velotour':
                        ogImage = '/data/velotouren/grandes_alpes/rennradtour_route_des_grandes_alpes_3633.jpg';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/gravel':
                        ogImage = '/data/exped/gravelpacking-schweiz/etappe-12-scuol-lago-san-giacomo/scarl-costainas-val-mora-fraele-bild-24.webp';
                        break;
                    case '/bildergalerien-nach-outdoor-aktivitaet/bikepacking':
                        ogImage = '/data/exped/gravelpacking-schweiz/etappe-11-davos-scuol/davos-flueelapass-scuol-bild-9.webp';
                        break;
                    default:
                        ogImage = '';
                }
                return ogImage;
            };
            // $inject annotation.
            // It provides $injector with information about dependencies to be injected into constructor
            // it is better to have it close to the constructor, because the parameters must match in count and type.
            // See http://docs.angularjs.org/guide/di
            IndexCtrl.$inject = [
                '$window',
                '$filter',
                '$scope',
                '$log',
                '$location',
                '$routeParams',
                '$http',
                'serverUrl',
                'Repository',
                'Tagging'
            ];
            return IndexCtrl;
        })();
        /**
         * @ngdoc object
         * @name index.controller:IndexCtrl
         *
         * @description
         *
         */
        angular.module('home').controller('IndexCtrl', IndexCtrl);
    })(IndexCtrl = Home.IndexCtrl || (Home.IndexCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var HomeCtrl;
(function (_HomeCtrl) {
    'use strict';
    var HomeCtrl = (function () {
        // dependencies are injected via AngularJS $injector
        function HomeCtrl($location, Authentication, $cookies, $rootScope, $scope, BookmarksService, _serverUrl_, $log, logger, $anchorScroll) {
            this.$location = $location;
            this.Authentication = Authentication;
            this.$cookies = $cookies;
            this.$rootScope = $rootScope;
            this.$scope = $scope;
            this.BookmarksService = BookmarksService;
            this._serverUrl_ = _serverUrl_;
            this.$log = $log;
            this.logger = logger;
            this.$anchorScroll = $anchorScroll;
            var vm = this;
            vm.ctrlName = 'HomeCtrl';
            vm.serverUrl = _serverUrl_;
            vm.currentDate = new Date();
            vm.currentFavorites = BookmarksService.getNumberOfProducts();
            /*
                  $scope.$watch(() => { return BookmarksService.getNumberOfProducts(); }, (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                      this.currentFavorites = BookmarksService.getNumberOfProducts();
                    }
                  });
            */
            // $rootScope.globals = this.$cookieStore.get('globals'); //read globals back from cookie as rootscope get's cleared on refresh!
            vm.triggerScroll = function () {
                for (var i = 0; i < 7; i++) {
                    $rootScope.$emit('list:filtered');
                }
                //console.log('triggerScroll in HomeCtrl called.');
            };
            vm.scrollToTop = function () {
                $anchorScroll();
                //console.log('scrollToTop in HomeCtrl called.');
            };
        }
        // $inject annotation.
        // It provides $injector with information about dependencies to be injected into constructor
        // it is better to have it close to the constructor, because the parameters must match in count and type.
        // See http://docs.angularjs.org/guide/di
        HomeCtrl.$inject = [
            '$location',
            'Authentication',
            '$cookies',
            '$rootScope',
            '$scope',
            'BookmarksService',
            'serverUrl',
            '$log',
            'Logger',
            '$anchorScroll',
        ];
        return HomeCtrl;
    })();
    /**
    * @ngdoc object
    * @name home.controller:HomeCtrl
    *
    * @description
    *
    */
    angular.module('home').controller('HomeCtrl', HomeCtrl);
})(HomeCtrl || (HomeCtrl = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var GalleryDetailCtrl;
    (function (_GalleryDetailCtrl) {
        'use strict';
        var GalleryDetailCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function GalleryDetailCtrl($window, $log, $routeParams, $http, repository, BookmarksService, tagging, $q, $filter, _serverUrl_, $rootScope, $anchorScroll) {
                this.$window = $window;
                this.$log = $log;
                this.$routeParams = $routeParams;
                this.$http = $http;
                this.repository = repository;
                this.BookmarksService = BookmarksService;
                this.tagging = tagging;
                this.$q = $q;
                this.$filter = $filter;
                this._serverUrl_ = _serverUrl_;
                this.$rootScope = $rootScope;
                this.$anchorScroll = $anchorScroll;
                // redirect page with .html extension to angular page
                if ($window.location.href.indexOf(".html") > 0) {
                    $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
                }
                var vm = this;
                vm.ctrlName = 'GalleryDetailCtrl';
                vm.serverUrl = _serverUrl_;
                vm.tags = tagging.getTags();
                vm.gallery = repository.getGallery();
                vm.isGallery = (typeof (vm.gallery) === "object");
                vm.addBookmark = addBookmark;
                if (vm.isGallery) {
                    vm.loadImages();
                    vm.galleryLabel = $routeParams.galleryLabel;
                    // validate some gallery attributes first, not every gallery has an altitudeLabel or region assigned
                    var altitudeLabel = vm.gallery.altitudeLabel ? ' ' + vm.gallery.altitudeLabel : '';
                    var country = vm.gallery.country ? $filter('convertIdToProperty')(vm.gallery.country, vm.tags, 'countries', 'name') : '';
                    var region = vm.gallery.region ? $filter('convertIdToProperty')(vm.gallery.region, vm.tags, 'regions', 'name') : '';
                    var activity = vm.gallery.activity[0] ? $filter('convertIdToProperty')(vm.gallery.activity[0], vm.tags, 'activities', 'name') : '';
                    var year = parseInt(vm.gallery.date);
                    vm.activityHeaderExtension = (activity != "Reise") ? activity : '';
                    vm.metaTitle = activity + " " + vm.gallery.name + altitudeLabel + ' (' + country + ' - ' + region + ', ' + year + ')';
                    vm.metaDescription = 'Fotoalbum ' + activity + ' ' + vm.gallery.name + altitudeLabel + ' in der Region ' + region + ' - ' + country + ' im Outdoor Fotoblog (' + year + ').';
                    vm.ogImage = vm.gallery.preImage ? vm.serverUrl.data + vm.gallery.path + "/" + vm.gallery.preImage : "";
                    vm.canonicalUrl = vm.serverUrl.host + vm.gallery.path;
                    vm.cartButtonTooltip = ($rootScope.globals && $rootScope.globals.currentUser) ? "zip File als Download aller Bilder dieser Galerie bestellen" : "Um ein zip File aller Bilder dieser Galerie zu bestellen bitte zuerst anmelden";
                    vm.scrollToTop = function () {
                        $anchorScroll();
                        //console.log('scrollToTop in HomeCtrl called.');
                    };
                    vm.addStructuredData = "[";
                    if (activity) {
                        // Breadcrumb
                        vm.addStructuredData += '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": [' + '\n{"@type": "ListItem",\n"position": 1,\n"item":"https://www.bergauf.ch/suche",\n"name": "Alle Bildergalerien"},' + '\n{"@type": "ListItem",\n"position": 2,\n"item":"https://www.bergauf.ch/bildergalerien-nach-outdoor-aktivitaet/' + vm.gallery.activity[0] + '",\n"name": "' + activity + '"},' + '\n{"@type": "ListItem",\n"position": 3,\n"name": "' + vm.gallery.name + '"}\n' + '\]' + '' + '\n},';
                    }
                    if (region) {
                        // Breadcrumb
                        vm.addStructuredData += '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": [' + '\n{"@type": "ListItem",\n"position": 1,\n"item":"https://www.bergauf.ch/suche",\n"name": "Alle Bildergalerien"},' + '\n{"@type": "ListItem",\n"position": 2,\n"item":"https://www.bergauf.ch/bildergalerien-nach-region/' + vm.gallery.region + '",\n"name": "' + region + '"},' + '\n{"@type": "ListItem",\n"position": 3,\n"name": "' + vm.gallery.name + '"}\n' + '\]' + '' + '\n},';
                    }
                    if (country) {
                        // Breadcrumb
                        vm.addStructuredData += '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": [' + '\n{"@type": "ListItem",\n"position": 1,\n"item":"https://www.bergauf.ch/suche",\n"name": "Alle Bildergalerien"},' + '\n{"@type": "ListItem",\n"position": 2,\n"item":"https://www.bergauf.ch/bildergalerien-nach-laender/' + vm.gallery.country + '",\n"name": "' + country + '"},' + '\n{"@type": "ListItem",\n"position": 3,\n"name": "' + vm.gallery.name + '"}\n' + '\]' + '' + '\n},';
                    }
                    // ImageGallery
                    vm.addStructuredData += '{\n"@context": "http://schema.org",' + '\n"@type": "ImageGallery",' + '\n"name": "' + vm.gallery.name + '",' + '\n"description": "' + vm.gallery.description + '",' + '\n"dateCreated": "' + vm.gallery.date + '",' + '\n"image": [\n';
                    vm.loadImages().then(function (result) {
                        vm.galleryImages.forEach(function (image, key, galleryImages) {
                            vm.addStructuredData += '{' + '\n"@type": "ImageObject",' + '\n"contentUrl": "https://www.bergauf.ch/data' + vm.gallery.path + "/" + image.filename + '",' + '\n"width": "' + image.width + '",' + '\n"height": "' + image.height + '"' + '\n}';
                            // check for last image
                            if (galleryImages.length - 1 != key) {
                                vm.addStructuredData += ',\n';
                            }
                            if (galleryImages.length - 1 == key) {
                                vm.addStructuredData += ']\n}\n]';
                            }
                        });
                    }).catch(function (err) {
                        // got error
                        vm.addStructuredData = "";
                    });
                }
                function addBookmark() {
                    //vm.$log.debug("galleryDetailController.addToBookmark: " + vm.gallery.path);
                    this.BookmarksService.addProduct({ path: vm.gallery.path });
                }
            }
            // load image details from pics.json file for each gallery
            GalleryDetailCtrl.prototype.loadImages = function () {
                var _this = this;
                var deferred = this.$q.defer();
                var log = this.$log;
                if (!this.galleryImages) {
                    var picsfile = this.serverUrl.data + this.gallery.path + '/pics.json';
                    this.$http.get(picsfile).then(function (data) {
                        var pics = [];
                        angular.forEach(data.data, function (pic) {
                            pics.push(pic);
                        });
                        _this.galleryImages = pics;
                        deferred.resolve(_this.galleryImages);
                    });
                }
                else {
                    deferred.resolve(this.galleryImages);
                }
                return deferred.promise;
            };
            // $inject annotation.
            // It provides $injector with information about dependencies to be injected into constructor
            // it is better to have it close to the constructor, because the parameters must match in count and type.
            // See http://docs.angularjs.org/guide/di
            GalleryDetailCtrl.$inject = [
                '$window',
                '$log',
                '$routeParams',
                '$http',
                'Repository',
                'BookmarksService',
                'Tagging',
                '$q',
                '$filter',
                'serverUrl',
                '$rootScope',
                '$anchorScroll'
            ];
            return GalleryDetailCtrl;
        })();
        /**
         * @ngdoc object
         * @name gallery.controller:GalleryDetailCtrl
         *
         * @description
         *
         */
        angular.module('home').controller('GalleryDetailCtrl', GalleryDetailCtrl);
    })(GalleryDetailCtrl = Home.GalleryDetailCtrl || (Home.GalleryDetailCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var DefaultCtrl;
    (function (_DefaultCtrl) {
        'use strict';
        var DefaultCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function DefaultCtrl($window, _serverUrl_) {
                this.$window = $window;
                this._serverUrl_ = _serverUrl_;
                // redirect page with .html extension to angular page
                if ($window.location.href.indexOf(".html") > 0) {
                    $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
                }
                var vm = this;
                vm.ctrlName = 'DefaultCtrl';
                vm.serverUrl = _serverUrl_;
            }
            // $inject annotation.
            // It provides $injector with information about dependencies to be injected into constructor
            // it is better to have it close to the constructor, because the parameters must match in count and type.
            // See http://docs.angularjs.org/guide/di
            DefaultCtrl.$inject = ['$window', 'serverUrl'];
            return DefaultCtrl;
        })();
        /**
         * @ngdoc object
         * @name home.controller:DefaultCtrl
         *
         * @description
         *
         */
        angular.module('home').controller('DefaultCtrl', DefaultCtrl);
    })(DefaultCtrl = Home.DefaultCtrl || (Home.DefaultCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../../typings/tsd.d.ts' />
var ContactCtrl;
(function (_ContactCtrl) {
    'use strict';
    var ContactCtrl = (function () {
        // dependencies are injected via AngularJS $injector
        function ContactCtrl($window, $http, _serverUrl_, $scope) {
            this.$window = $window;
            this.$http = $http;
            this._serverUrl_ = _serverUrl_;
            this.$scope = $scope;
            // redirect page with .html extension to angular page
            if ($window.location.href.indexOf(".html") > 0) {
                $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
            }
            var vm = this;
            vm.ctrlName = 'ContactCtrl';
            vm.serverUrl = _serverUrl_;
            vm.canonicalUrl = vm.serverUrl.host + "/contact";
            // ToDo: use toastr for error messages
            $scope.result = 'hidden';
            $scope.resultMessage;
            $scope.formData; //formData is an object holding the name, email, subject, and message
            $scope.submitButtonDisabled = false;
            $scope.submitted = false; //used so that form errors are shown only after the form has been submitted
            $scope.submit = function (contactform, e) {
                $scope.submitted = true;
                $scope.submitButtonDisabled = true;
                if (contactform.$valid) {
                    $http({
                        method: 'POST',
                        url: 'https://www.bergauf.ch/bin/contact-form.php',
                        data: $.param($scope.formData),
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' } //set the headers so angular passing info as form data (not request payload)
                    }).success(function (data) {
                        // console.log(data);
                        if (data.success) {
                            $scope.submitButtonDisabled = true;
                            $scope.resultMessage = data.message;
                            $scope.result = 'bg-success';
                        }
                        else {
                            $scope.submitButtonDisabled = false;
                            $scope.resultMessage = data.message;
                            $scope.result = 'bg-danger';
                        }
                    });
                }
                else {
                    $scope.submitButtonDisabled = false;
                    $scope.resultMessage = 'Bitte alle Pflichtfelder korrekt ausfüllen.';
                    $scope.result = 'bg-danger';
                }
                e.preventDefault();
            };
        }
        // $inject annotation.
        // It provides $injector with information about dependencies to be injected into constructor
        // it is better to have it close to the constructor, because the parameters must match in count and type.
        // See http://docs.angularjs.org/guide/di
        ContactCtrl.$inject = ['$window', '$http', 'serverUrl', '$scope'];
        return ContactCtrl;
    })();
    /**
     * @ngdoc object
     * @name home.controller:ContactCtrl
     *
     * @description
     *
     */
    angular.module('home').controller('ContactCtrl', ContactCtrl);
})(ContactCtrl || (ContactCtrl = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var CartCtrl;
    (function (_CartCtrl) {
        'use strict';
        var CartCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function CartCtrl(CartService, User, $rootScope, $log, $location, Logger) {
                this.CartService = CartService;
                this.User = User;
                this.$rootScope = $rootScope;
                this.$log = $log;
                this.$location = $location;
                this.Logger = Logger;
                var vm = this; // initialize class variables
                vm.ctrlName = 'CartCtrl';
                vm.user = null;
                vm.cart = null;
                vm.products = [];
                vm.deleteProduct = deleteProduct; // points to the function, makes it a public function.
                vm.checkout = checkout;
                loadCurrentUser();
                this.getCart();
                function loadCurrentUser() {
                    User.getByUsername($rootScope.globals.currentUser.username).then(function (user) {
                        vm.user = user.data;
                        $log.debug("cart controller:loadCurrentUser " + vm.user.firstname);
                    });
                }
                function deleteProduct(product) {
                    vm.products = this.CartService.removeProduct(product);
                    vm.cart.products = vm.products;
                }
                function checkout() {
                    vm.dataLoading = true; // rest-call in progress
                    if (vm.products.length > 0) {
                        this.CartService.checkout(vm.cart).then(function (response) {
                            if (response.data) {
                                Logger.logSuccess('Ihre Bestellung wurde erfolgreich versandt.', 'empty', this, true);
                                CartService.emptyCart();
                            }
                            else {
                                Logger.logError(response.message, 'empty', this, true);
                                vm.dataLoading = false;
                            }
                        });
                    }
                }
            }
            CartCtrl.prototype.getCart = function () {
                this.cart = this.CartService.getCart(); // get cart-object
                for (var i = 0; i < this.cart.products.length; i++) {
                    this.products.push(this.cart.products[i]);
                }
                this.$log.debug("cart-controller:getCart " + this.cart.username);
            };
            // $inject annotation.
            // It provides $injector with information about dependencies to be injected into constructor
            // it is better to have it close to the constructor, because the parameters must match in count and type.
            // See http://docs.angularjs.org/guide/di
            CartCtrl.$inject = [
                'CartService',
                'User',
                '$rootScope',
                '$log',
                '$location',
                'Logger'
            ];
            return CartCtrl;
        })();
        /**
         * @ngdoc object
         * @name home.controller:CartCtrl
         *
         * @descriptionme
         *
         */
        angular.module('home').controller('CartCtrl', CartCtrl);
    })(CartCtrl = Home.CartCtrl || (Home.CartCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var BookmarksCtrl;
    (function (_BookmarksCtrl) {
        'use strict';
        var BookmarksCtrl = (function () {
            // dependencies are injected via AngularJS $injector
            function BookmarksCtrl(BookmarksService, $log, $location, Logger) {
                this.BookmarksService = BookmarksService;
                this.$log = $log;
                this.$location = $location;
                this.Logger = Logger;
                var vm = this; // initialize class variables
                vm.ctrlName = 'BookmarksCtrl';
                vm.bookmarks = null;
                vm.products = [];
                vm.deleteProduct = deleteProduct; // points to the function, makes it a public function.
                this.getBookmarks();
                function deleteProduct(product) {
                    vm.products = this.BookmarksService.removeProduct(product);
                    vm.bookmarks.products = vm.products;
                }
            }
            BookmarksCtrl.prototype.getBookmarks = function () {
                this.bookmarks = this.BookmarksService.getBookmarks(); // get bookmarks-object
                for (var i = 0; i < this.bookmarks.products.length; i++) {
                    this.products.push(this.bookmarks.products[i]);
                }
                //this.$log.debug("bookmarks-controller getFavorites():  " + this.products.length);
            };
            // $inject annotation.
            BookmarksCtrl.$inject = [
                'BookmarksService',
                '$log',
                '$location',
                'Logger'
            ];
            return BookmarksCtrl;
        })();
        /**
         * @ngdoc object
         * @name home.controller:BookmarksCtrl
         *
         * @descriptionme
         *
         */
        angular.module('home').controller('BookmarksCtrl', BookmarksCtrl);
    })(BookmarksCtrl = Home.BookmarksCtrl || (Home.BookmarksCtrl = {}));
})(Home || (Home = {}));

///<reference path='../../../typings/tsd.d.ts' />
var SwisstopoMap;
(function (SwisstopoMap) {
    'use strict';
    /**
    * @ngdoc directive
    * @name home.directive:swisstopoMap
    * @restrict EA
    * @element
    *
    * @description
    *
    * @example
      <example module="home">
        <file name="index.html">
          <swisstopo-map></swisstopo-map>
        </file>
      </example>
    *
    */
    angular.module('home').directive('swisstopoMap', swisstopoMap);
    function swisstopoMap($timeout) {
        return {
            restrict: 'EA',
            scope: {},
            templateUrl: 'home/directives/swisstopo-map-directive.tpl.html',
            replace: false,
            controllerAs: 'swisstopoMap',
            controller: function () {
                var vm = this;
                vm.name = 'swisstopoMap';
            },
            link: function (scope, element, attrs) {
                /*jshint unused:false */
                /*eslint "no-unused-vars": [2, {"args": "none"}]*/
                /*
                $timeout(function () {
        
                  var y = galleryDetail.gallery.map.y;
                  var x = galleryDetail.gallery.map.x;
        
                  console.log('y: ' + y);
                  console.log('x: ' + x);
        
                  var layer = ga.layer.create('ch.swisstopo.pixelkarte-farbe');
                  var map = new ga.Map({
                    target: 'map',
                    layers: [layer],
                    view: new ol.View({
                      resolution: 500,
                      center: [y,x]
                      center: [712785.19,190993.05]
                    })
                  });
        
                }, 1000);
                */
            }
        };
    }
})(SwisstopoMap || (SwisstopoMap = {}));

///<reference path='../../min.references.ts' />
var GalleryItem;
(function (GalleryItem) {
    'use strict';
    var galleryItemController = (function () {
        function galleryItemController(repository, serverUrl) {
            this.repository = repository;
            this.serverUrl = serverUrl;
            // repository and serverUrl are now properties of the controller
        }
        galleryItemController.prototype.getGallery = function (path) {
            return this.repository.getGalleryFromPath(path);
        };
        galleryItemController.prototype.getServerUrl = function () {
            return this.serverUrl;
        };
        galleryItemController.$inject = ['Repository', 'serverUrl'];
        return galleryItemController;
    })();
    angular.module('home').directive('galleryItem', galleryItem);
    function galleryItem() {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'home/directives/gallery-item-directive.tpl.html',
            replace: false,
            controllerAs: 'galleryItem',
            controller: galleryItemController,
            link: function (scope, element, attrs, controller) {
                /*jshint unused:false */
                /*eslint "no-unused-vars": [2, {"args": "none"}]*/
                attrs.$observe('path', function (path) {
                    scope.galleryObj = controller.getGallery(path);
                    scope.serverUrl = controller.getServerUrl();
                });
            }
        };
    }
})(GalleryItem || (GalleryItem = {}));

///<reference path='../../../typings/tsd.d.ts' />
var serverUrl;
(function (serverUrl) {
    'use strict';
    /**
    * @ngdoc service
    * @name home.constant:serverUrl
    *
    * @description
    *
    */
    angular.module('home').constant('serverUrl', {
        // used for user login and cart
        server: 'http://localhost:3003',
        // used for image data, use 'http://localhost:3003/data' for local sample data
        // DEV hosts
        //data: 'http://localhost:3003/data',
        //host: 'http://192.168.1.167:3000',
        // PROD hosts
        data: 'https://www.bergauf.ch/data',
        host: 'https://www.bergauf.ch',
        prefetch: 'www.bergauf.ch'
    });
})(serverUrl || (serverUrl = {}));

///<reference path='../min.references.ts' />
var home;
(function (home) {
    'use strict';
    angular.module('home').config(config);
    function config($routeProvider, $locationProvider) {
        $routeProvider.when('/index', {
            templateUrl: 'home/views/simple-home.tpl.html',
            controller: 'DefaultCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/suche', {
            templateUrl: 'home/views/home.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/bildergalerien-nach-laender/:countryId', {
            templateUrl: 'home/views/home.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/bildergalerien-nach-region/:regionId', {
            templateUrl: 'home/views/home.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/bildergalerien-nach-outdoor-aktivitaet/:activityId', {
            templateUrl: 'home/views/home.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/skitouren/:galleryLabel', {
            templateUrl: 'home/views/gallery-detail.tpl.html',
            controller: 'GalleryDetailCtrl',
            controllerAs: 'galleryDetail',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/bergtouren/:galleryLabel', {
            templateUrl: 'home/views/gallery-detail.tpl.html',
            controller: 'GalleryDetailCtrl',
            controllerAs: 'galleryDetail',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/velotouren/:galleryLabel', {
            templateUrl: 'home/views/gallery-detail.tpl.html',
            controller: 'GalleryDetailCtrl',
            controllerAs: 'galleryDetail',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/gravelpacking-piemont', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/gravelpacking-schweiz', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-marokko', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-kambodscha', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-kolumbien', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-sulawesi', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-island', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-cabo-verde', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-kuba', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/reise-nach-myanmar', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/oman', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/skitouren-im-iran', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/eastgreenland06', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/maroc04', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/mtb-reise-ladakh', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/altiplano', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/:galleryParent/:galleryLabel', {
            templateUrl: 'home/views/gallery-detail.tpl.html',
            controller: 'GalleryDetailCtrl',
            controllerAs: 'galleryDetail',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/exped/:galleryLabel', {
            templateUrl: 'home/views/gallery-detail.tpl.html',
            controller: 'GalleryDetailCtrl',
            controllerAs: 'galleryDetail',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/userhome', {
            templateUrl: 'home/views/userhome.tpl.html',
            controller: 'UserhomeCtrl',
            controllerAs: 'vm',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/panoramas', {
            templateUrl: 'home/views/index.tpl.html',
            controller: 'IndexCtrl',
            controllerAs: 'home',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/panoramas/:galleryLabel', {
            templateUrl: 'home/views/gallery-detail.tpl.html',
            controller: 'GalleryDetailCtrl',
            controllerAs: 'galleryDetail',
            resolve: {
                'GalleryData': ['Repository', function (Repository) {
                    return Repository.loadGalleryData();
                }],
                'Tags': ['Tagging', function (Tagging) {
                    return Tagging.loadAllTags();
                }]
            }
        }).when('/impressum', {
            templateUrl: 'home/views/impressum.tpl.html',
            controller: 'DefaultCtrl',
            controllerAs: 'home'
        }).when('/contact', {
            templateUrl: 'home/views/contact.tpl.html',
            controller: 'ContactCtrl',
            controllerAs: 'contact'
        }).when('/favoriten', {
            templateUrl: 'home/views/bookmarks.tpl.html',
            controller: 'BookmarksCtrl',
            controllerAs: 'bookmarks'
        }).when('/gravelpacking-piemont', {
            redirectTo: '/exped/gravelpacking-piemont'
        }).when('/gravelpacking-schweiz', {
            redirectTo: '/exped/gravelpacking-schweiz'
        }).when('/reise-nach-marokko', {
            redirectTo: '/exped/reise-nach-marokko'
        }).when('/reise-nach-kambodscha', {
            redirectTo: '/exped/reise-nach-kambodscha'
        }).when('/reise-nach-kolumbien', {
            redirectTo: '/exped/reise-nach-kolumbien'
        }).when('/reise-nach-sulawesi', {
            redirectTo: '/exped/reise-nach-sulawesi'
        }).when('/reise-nach-island', {
            redirectTo: '/exped/reise-nach-island'
        }).when('/reise-nach-cabo-verde', {
            redirectTo: '/exped/reise-nach-cabo-verde'
        }).when('/reise-nach-kuba', {
            redirectTo: '/exped/reise-nach-kuba'
        }).when('/reise-nach-myanmar', {
            redirectTo: '/exped/reise-nach-myanmar'
        }).when('/oman', {
            redirectTo: '/exped/oman'
        }).when('/skitouren-im-iran', {
            redirectTo: '/exped/skitouren-im-iran'
        }).when('/eastgreenland06', {
            redirectTo: '/exped/eastgreenland06'
        }).when('/maroc04', {
            redirectTo: '/exped/maroc04'
        }).when('/mtb-reise-ladakh', {
            redirectTo: '/exped/mtb-reise-ladakh'
        }).when('/altiplano', {
            redirectTo: '/exped/altiplano'
        }).when('/skitouren', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/skitour'
        }).when('/bergtouren', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/bergtour'
        }).when('/velotouren', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/velotour'
        }).when('/exped', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/reise'
        }).when('/hochtouren', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/hochtour'
        }).when('/klettertouren', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/klettertour'
        }).when('/alpinwanderungen', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/alpinwanderung'
        }).when('/wanderungen', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/wanderung'
        }).when('/bike-and-hike', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/bike-and-hike'
        }).when('/mtb', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/mtb'
        }).when('/rennvelo', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/rennvelo'
        }).when('/gravel', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/gravel'
        }).when('/bikepacking', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/bikepacking'
        }).when('/ski-expeditionen', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/ski-expedition'
        }).when('/trekking', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/trekking'
        }).when('/reisen', {
            redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/reise'
        }).otherwise({
            redirectTo: '/suche'
        });
        // use the HTML5 History API
        $locationProvider.html5Mode(true);
    }
})(home || (home = {}));

///<reference path='min.references.ts' />
var bergauf;
(function (bergauf) {
    'use strict';
    /* @ngdoc object
     * @name bergauf
     * @description
     *
     */
    angular.module('bergauf', [
        'ngRoute',
        'viewhead',
        'ngAnimate',
        'mgcrea.ngStrap',
        'dcbImgFallback',
        'LocalStorageModule',
        'home'
    ]).config(function (localStorageServiceProvider) {
        localStorageServiceProvider.setPrefix('bergauf').setStorageType('localStorage').setNotify(true, true);
    });
})(bergauf || (bergauf = {}));

///<reference path='min.references.ts' />
var bergauf;
(function (bergauf) {
    'use strict';
    angular.module('bergauf').config(config);
    function config($routeProvider) {
        $routeProvider.otherwise({
            redirectTo: '/index'
        });
    }
})(bergauf || (bergauf = {}));

/// <reference path="../typings/tsd.d.ts"/>
///<reference path='home/services/authentication-service.ts' />
///<reference path='home/services/repository-service.ts' />
///<reference path='home/services/user-service.ts' />
///<reference path='home/services/cart-service.ts' />
///<reference path='home/services/bookmarks-service.ts' />
///<reference path='home/services/logger-service.ts' />
///<reference path='home/services/tagging-service.ts' />
///<reference path='home/factories/authentication-interceptor.ts' />
///<reference path='home/ts/gallery.ts' />
///<reference path='home/ts/image.ts' />
///<reference path='home/ts/map.ts' />
///<reference path='home/ts/cart.ts' />
///<reference path='home/ts/bookmarks.ts' />
///<reference path='home/ts/product.ts' />
///<reference path='home/ts/tag.ts' />
///<reference path='home/ts/order.ts' />
///<reference path='home/directives/gallery-thumbnail-directive.ts' />
///<reference path='home/directives/lazy-directive.ts' />
///<reference path='home/directives/photoswipe-directive.ts' />
///<reference path='home/filters/activity-filter.ts' />

///<reference path='../../min.references.ts' />
var activity;
(function (_activity) {
    'use strict';
    /**
    * @ngdoc filter
    * @name home.filter:activity
    *
    * @description
    *
    * @param {Array} galleries The array to filter  @param {Array} galleries The array to filter
    * @param {String} theme The gallery theme
    * @returns {Array} The filtered array
    *
    */
    angular.module('home').filter('activity', activity);
    function activity() {
        return function (galleries, theme) {
            var temp = [];
            angular.forEach(galleries, function (gallery) {
                //console.log('Filter type:' + theme);
                // skitouren and bergtouren index have different filters
                if (theme === '/skitouren') {
                    if (gallery.activity.indexOf('skitour') >= 0) {
                        temp.push(gallery);
                    }
                }
                else if (theme === '/bergtouren') {
                    if (gallery.activity.indexOf('bergtour') >= 0 || gallery.activity.indexOf('hochtour') >= 0 || gallery.activity.indexOf('klettertour') >= 0 || gallery.activity.indexOf('bike-and-hike') >= 0 || gallery.activity.indexOf('alpinwanderung') >= 0 || gallery.activity.indexOf('wanderung') >= 0) {
                        temp.push(gallery);
                    }
                }
                else if (gallery.path.indexOf(theme) >= 0) {
                    temp.push(gallery);
                }
            });
            return temp;
        };
    }
})(activity || (activity = {}));

///<reference path='../../min.references.ts' />
var PhotoSwipe;
(function (PhotoSwipe) {
    'use strict';
    /**
     * @ngdoc directive
     * @name home.directive:photoswipe
     * @restrict C
     * @element
     *
     * @description
     *
     */
    angular.module('home').directive('photoswipe', photoswipe);
    function photoswipe($timeout) {
        return {
            restrict: 'C',
            scope: {},
            replace: false,
            controllerAs: 'photoswipe',
            controller: function () {
                var vm = this;
                vm.name = 'photoswipe';
            },
            link: function (scope, element, attrs) {
                /*jshint unused:false */
                /*eslint "no-unused-vars": [2, {"args": "none"}]*/
                $timeout(function () {
                    var initPhotoSwipeFromDOM = function (gallerySelector) {
                        // parse slide data (url, title, size ...) from DOM elements
                        // (children of gallerySelector)
                        var parseThumbnailElements = function (el) {
                            var thumbElements = el.childNodes, numNodes = thumbElements.length, items = [], figureEl, linkEl, size, item;
                            for (var i = 0; i < numNodes; i++) {
                                figureEl = thumbElements[i]; // <figure> element
                                // include only element nodes
                                if (figureEl.nodeType !== 1) {
                                    continue;
                                }
                                linkEl = figureEl.children[0]; // <a> element
                                size = linkEl.getAttribute('data-size').split('x');
                                // create slide object
                                item = {
                                    src: linkEl.getAttribute('href'),
                                    w: parseInt(size[0], 10),
                                    h: parseInt(size[1], 10)
                                };
                                if (figureEl.children.length > 1) {
                                    // <figcaption> content
                                    item.title = figureEl.children[1].innerHTML;
                                }
                                if (linkEl.children.length > 0) {
                                    // <img> thumbnail element, retrieving thumbnail url
                                    item.msrc = linkEl.children[0].getAttribute('src');
                                }
                                item.el = figureEl; // save link to element for getThumbBoundsFn
                                items.push(item);
                            }
                            return items;
                        };
                        // find nearest parent element
                        var closest = function closest(el, fn) {
                            return el && (fn(el) ? el : closest(el.parentNode, fn));
                        };
                        // triggers when user clicks on thumbnail
                        var onThumbnailsClick = function (e) {
                            e = e || window.event;
                            e.preventDefault ? e.preventDefault() : e.returnValue = false;
                            var eTarget = e.target || e.srcElement;
                            // find root element of slide
                            var clickedListItem = closest(eTarget, function (el) {
                                return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
                            });
                            if (!clickedListItem) {
                                return;
                            }
                            // find index of clicked item by looping through all child nodes
                            // alternatively, you may define index via data- attribute
                            var clickedGallery = clickedListItem.parentNode, childNodes = clickedListItem.parentNode.childNodes, numChildNodes = childNodes.length, nodeIndex = 0, index;
                            for (var i = 0; i < numChildNodes; i++) {
                                if (childNodes[i].nodeType !== 1) {
                                    continue;
                                }
                                if (childNodes[i] === clickedListItem) {
                                    index = nodeIndex;
                                    break;
                                }
                                nodeIndex++;
                            }
                            if (index >= 0) {
                                // open PhotoSwipe if valid index found
                                openPhotoSwipe(index, clickedGallery, false);
                            }
                            return false;
                        };
                        // parse picture index and gallery index from URL (#&pid=1&gid=2)
                        var photoswipeParseHash = function () {
                            var hash = window.location.hash.substring(1), params = {};
                            if (hash.length < 5) {
                                return params;
                            }
                            var vars = hash.split('&');
                            for (var i = 0; i < vars.length; i++) {
                                if (!vars[i]) {
                                    continue;
                                }
                                var pair = vars[i].split('=');
                                if (pair.length < 2) {
                                    continue;
                                }
                                params[pair[0]] = pair[1];
                            }
                            if (params.gid) {
                                params.gid = parseInt(params.gid, 10);
                            }
                            if (!params.hasOwnProperty('pid')) {
                                return params;
                            }
                            params.pid = parseInt(params.pid, 10);
                            return params;
                        };
                        var openPhotoSwipe = function (index, galleryElement, disableAnimation) {
                            var pswpElement = document.querySelectorAll('.pswp')[0], gallery, options, items;
                            items = parseThumbnailElements(galleryElement);
                            // define options (if needed)
                            options = {
                                index: index,
                                shareButtons: [
                                    { id: 'facebook', label: 'Share on Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}' },
                                    { id: 'twitter', label: 'Tweet', url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}' },
                                    { id: 'pinterest', label: 'Pin it', url: 'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}' },
                                    { id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }
                                ],
                                // define gallery index (for URL)
                                galleryUID: galleryElement.getAttribute('data-pswp-uid'),
                                getThumbBoundsFn: function (index) {
                                    // See Options -> getThumbBoundsFn section of documentation for more info
                                    var thumbnail = items[index].el.getElementsByTagName('img')[0], pageYScroll = window.pageYOffset || document.documentElement.scrollTop, rect = thumbnail.getBoundingClientRect();
                                    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                                }
                            };
                            if (disableAnimation) {
                                options.showAnimationDuration = 0;
                            }
                            // Pass data to PhotoSwipe and initialize it
                            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
                            gallery.init();
                        };
                        // loop through all gallery elements and bind events
                        var galleryElements = document.querySelectorAll(gallerySelector);
                        for (var i = 0, l = galleryElements.length; i < l; i++) {
                            galleryElements[i].setAttribute('data-pswp-uid', (i + 1).toString());
                            galleryElements[i].onclick = onThumbnailsClick;
                        }
                        // Parse URL and open gallery if it contains #&pid=3&gid=1
                        var hashData = photoswipeParseHash();
                        if (hashData.pid > 0 && hashData.gid > 0) {
                            openPhotoSwipe(hashData.pid - 1, galleryElements[hashData.gid - 1], true);
                        }
                    };
                    // execute above function
                    initPhotoSwipeFromDOM('.gallery');
                }, 1000);
            }
        };
    }
})(PhotoSwipe || (PhotoSwipe = {}));

///<reference path='../../min.references.ts' />
var Lazy;
(function (Lazy) {
    'use strict';
    /**
    * @ngdoc directive
    * @name home.directive:lazy
    * @restrict EA
    * @element
    *
    * @description
    *
    * @example
      <example module="home">
        <file name="index.html">
          <lazy></lazy>
        </file>
      </example>
    *
    */
    angular.module('home').directive('lazy', lazy);
    function lazy($timeout) {
        return {
            restrict: 'C',
            scope: {},
            replace: false,
            controllerAs: 'lazy',
            controller: function () {
                var vm = this;
                vm.name = 'lazy';
            },
            link: function (scope, element, attrs) {
                /*jshint unused:false */
                /*eslint "no-unused-vars": [2, {"args": "none"}]*/
                $timeout(function () {
                    $(element).lazyload({
                        event: 'scroll change keyup',
                        threshold: 150,
                        effect: 'fadeIn',
                        effectspeed: 500,
                        'skip_invisible': false
                    });
                }, 0);
                // set title hack
                var test = document.querySelector('meta[property="og:title"]');
                var content = test && test.getAttribute("content");
                document.title = content;
            }
        };
    }
})(Lazy || (Lazy = {}));

///<reference path='../../min.references.ts' />
var GalleryThumbnail;
(function (GalleryThumbnail) {
    'use strict';
    var galleryThumbnailController = (function () {
        function galleryThumbnailController(tagging, serverUrl, $anchorScroll) {
            this.tagging = tagging;
            this.serverUrl = serverUrl;
            this.$anchorScroll = $anchorScroll;
            // countries, regions and activities are now properties of the controller
        }
        galleryThumbnailController.prototype.getTags = function () {
            return this.tagging.getTags();
        };
        galleryThumbnailController.prototype.getServerUrl = function () {
            return this.serverUrl;
        };
        galleryThumbnailController.prototype.anchorScroll = function () {
            return this.$anchorScroll;
        };
        galleryThumbnailController.$inject = ['Tagging', 'serverUrl', '$anchorScroll'];
        return galleryThumbnailController;
    })();
    angular.module('home').directive('galleryThumbnail', galleryThumbnail);
    function galleryThumbnail() {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'home/directives/gallery-thumbnail-directive.tpl.html',
            replace: false,
            controllerAs: 'galleryThumbnail',
            controller: galleryThumbnailController,
            link: function (scope, element, attrs, controller) {
                /*jshint unused:false */
                /*eslint "no-unused-vars": [2, {"args": "none"}]*/
                attrs.$observe('gallery', function (gallery) {
                    scope.galleryObj = scope.$eval(gallery);
                });
                scope.tagsObj = controller.getTags();
                scope.serverUrl = controller.getServerUrl();
                scope.triggerScroll = function () {
                    for (var i = 0; i < 7; i++) {
                        scope.$emit('list:filtered');
                    }
                    //console.log('triggerScroll in gallery-thumbnail directive called');
                };
                /*
                scope.scrollToTop  = function () {
                  console.log('scrollToTop in gallery-thumbnail directive called');
                  controller.anchorScroll();
                };
                */
            }
        };
    }
})(GalleryThumbnail || (GalleryThumbnail = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../../typings/tsd.d.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../../typings/tsd.d.ts' />
var Home;
(function (Home) {
    var Data;
    (function (Data) {
        'use strict';
    })(Data = Home.Data || (Home.Data = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Factories;
    (function (Factories) {
        'use strict';
        var AuthenticationInterceptor = (function () {
            function AuthenticationInterceptor($q, $cookies) {
                var _this = this;
                this.$q = $q;
                this.$cookies = $cookies;
                //Method name should be exactly "response" - http://docs.angularjs.org/api/ng/service/$http
                this.request = function (config) {
                    config.headers = config.headers || {};
                    var globalsCookie = _this.$cookies.get('globals');
                    //console.log(config); // debug only
                    if (globalsCookie && globalsCookie.currentUser.token) {
                        config.headers.Authorization = 'Bearer ' + globalsCookie.currentUser.token;
                    }
                    return config;
                };
                this.responseError = function (rejection) {
                    // console.log(rejection.status);
                    if (rejection.status === 401) {
                    }
                    // Otherwise, default behavior
                    return _this.$q.reject(rejection);
                };
            }
            AuthenticationInterceptor.Factory = function ($q, $cookies) {
                return new AuthenticationInterceptor($q, $cookies);
            };
            AuthenticationInterceptor.$inject = ['$cookies'];
            return AuthenticationInterceptor;
        })();
        Factories.AuthenticationInterceptor = AuthenticationInterceptor;
        angular.module('home').factory('AuthenticationInterceptor', AuthenticationInterceptor);
    })(Factories = Home.Factories || (Home.Factories = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Services;
    (function (Services) {
        'use strict';
        var Tagging = (function () {
            function Tagging($log, $http, $q, serverUrl) {
                this.$log = $log;
                this.$http = $http;
                this.$q = $q;
                this.serverUrl = serverUrl;
            }
            Tagging.prototype.get = function () {
                return 'Tagging';
            };
            Tagging.prototype.loadAllTags = function () {
                var _this = this;
                var deferred = this.$q.defer();
                //var jsonfile = this.serverUrl.data + '/tags-20250330.json';
                var jsonfile = '/json/tags-20250330.json';
                if (!this.allTags) {
                    this.$http.get(jsonfile).then(function (data) {
                        var tags = [];
                        angular.forEach(data.data, function (tag) {
                            tags.push(tag);
                        });
                        _this.allTags = tags;
                        deferred.resolve(_this.allTags);
                    });
                }
                else {
                    deferred.resolve(this.allTags);
                }
                return deferred.promise;
            };
            Tagging.prototype.getTags = function () {
                return this.allTags;
            };
            // ToDo: add Constants for namespace indexes, e.g NAMESPACE.COUNTRIES = 1
            Tagging.prototype.getActivityTags = function () {
                return this.getTagsByNamespace(0);
            };
            Tagging.prototype.getCountryTags = function () {
                return this.getTagsByNamespace(1);
            };
            Tagging.prototype.getRegionTags = function () {
                return this.getTagsByNamespace(2);
            };
            Tagging.prototype.getTagsByNamespace = function (i) {
                return this.allTags[i];
            };
            Tagging.$inject = ['$log', '$http', '$q', 'serverUrl'];
            return Tagging;
        })();
        /**
         * @ngdoc service
         * @name home.service:Tagging
         *
         * @description
         *
         */
        angular.module('home').service('Tagging', Tagging);
    })(Services = Home.Services || (Home.Services = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Logger;
(function (_Logger) {
    'use strict';
    var Logger = (function () {
        function Logger($log) {
            var _this = this;
            this.$log = $log;
            this.log = function (message, data, source, showToast) { return _this.logInner(message, data, source, showToast); };
            this.logWarning = function (message, data, source, showToast) { return _this.logWarningInner(message, data, source, showToast); };
            this.logSuccess = function (message, data, source, showToast) { return _this.logSuccessInner(message, data, source, showToast); };
            this.logError = function (message, data, source, showToast) { return _this.logErrorInner(message, data, source, showToast); };
            this.getLogFn = function (moduleId, fnName) { return _this.getLogFnInner(moduleId, fnName); };
            toastr.options.timeOut = 3000;
            toastr.options.showDuration = 2400;
            toastr.options.hideDuration = 500;
            toastr.options.positionClass = "toast-top-center";
            toastr.options.closeButton = true;
            toastr.options.preventDuplicates = true;
        }
        Logger.prototype.get = function () {
            return 'Logger';
        };
        Logger.prototype.getLogFnInner = function (moduleId, fnName) {
            fnName = fnName || 'log';
            switch (fnName.toLowerCase()) {
                case 'success':
                    fnName = 'logSuccess';
                    break;
                case 'error':
                    fnName = 'logError';
                    break;
                case 'warn':
                    fnName = 'logWarning';
                    break;
                case 'warning':
                    fnName = 'logWarning';
                    break;
            }
            var logFn = this[fnName] || this.log;
            return function (msg, data, showToast) {
                logFn(msg, data, moduleId, (showToast === undefined) ? true : showToast);
            };
        };
        Logger.prototype.logInner = function (message, data, source, showToast) {
            this.logIt(message, data, source, showToast, 'info');
        };
        Logger.prototype.logWarningInner = function (message, data, source, showToast) {
            this.logIt(message, data, source, showToast, 'warning');
        };
        Logger.prototype.logSuccessInner = function (message, data, source, showToast) {
            this.logIt(message, data, source, showToast, 'success');
        };
        Logger.prototype.logErrorInner = function (message, data, source, showToast) {
            this.logIt(message, data, source, showToast, 'error');
        };
        Logger.prototype.logIt = function (message, data, source, showToast, toastType) {
            var write = (toastType === 'error') ? this.$log.error : this.$log.log;
            source = source ? '[' + source + '] ' : '';
            write(source, message, data);
            if (showToast) {
                if (toastType === 'error') {
                    toastr.error(message);
                }
                else if (toastType === 'warning') {
                    toastr.warning(message);
                }
                else if (toastType === 'success') {
                    toastr.success(message);
                }
                else {
                    toastr.info(message);
                }
            }
        };
        Logger.$inject = ['$log'];
        return Logger;
    })();
    /**
     * @ngdoc service
     * @name home.service:Logger
     *
     * @description
     *
     */
    angular.module('home').service('Logger', Logger);
})(Logger || (Logger = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Services;
    (function (Services) {
        'use strict';
        // service to handle a shopping cart's data in a cookie on the client side.
        var BookmarksService = (function () {
            function BookmarksService(localStorageService, $log, $http, logger, serverUrl) {
                this.localStorageService = localStorageService;
                this.$log = $log;
                this.$http = $http;
                this.logger = logger;
                this.serverUrl = serverUrl;
                this.localstorageKey = 'favoriteGalleries';
            }
            // read bookmarks from local storage into a bookmarks-object.
            BookmarksService.prototype.getBookmarks = function () {
                var products = this.readBookmarks();
                var bookmarks = { products: [] };
                for (var i = 0; i < products.length; i++) {
                    bookmarks.products.push(products[i]);
                }
                return bookmarks;
            };
            // add a product to the bookmarksCookie
            BookmarksService.prototype.addProduct = function (product) {
                var products = this.readBookmarks();
                if (!this.containsProduct(products, product)) {
                    products.push({
                        path: product.path
                    });
                    this.logger.logSuccess('Diese Bildergalerie wurde zu den Favoriten hinzugefügt.', 'empty', this, true);
                }
                else {
                    this.logger.logWarning('Diese Bildergalerie ist schon ein Favorit.', 'empty', this, true);
                }
                this.writeBookmarks(products);
                return products;
            };
            // remove a product from the bookmarksCookie
            BookmarksService.prototype.removeProduct = function (product) {
                var products = this.readBookmarks();
                for (var i = 0; i < products.length; i++) {
                    if (product.path == products[i].path) {
                        products.splice(i, 1);
                        this.logger.logSuccess('Die Bildergalerie wurde von den Favoriten gelöscht.', 'empty', this, true);
                    }
                }
                this.writeBookmarks(products);
                return products;
            };
            BookmarksService.prototype.getNumberOfProducts = function () {
                var products = this.readBookmarks();
                //this.$log.debug("BookmarksService.getNumberOfProducts(): " + products.length);
                return products.length;
            };
            BookmarksService.prototype.emptyBookmarks = function () {
                this.localStorageService.remove(this.localstorageKey);
                //this.$log.debug("BookmarksService.emptyBookmarks() called.");
            };
            BookmarksService.prototype.get = function () {
                return 'BookmarksService';
            };
            // private functions
            BookmarksService.prototype.handleSuccess = function (data) {
                return data;
            };
            BookmarksService.prototype.handleError = function (error) {
                return function () {
                    return { success: false, message: error };
                };
            };
            BookmarksService.prototype.containsProduct = function (array, elem) {
                var len = array.length;
                for (var i = 0; i < len; i++) {
                    if (array[i].path == elem.path) {
                        return true;
                    }
                }
                return false;
            };
            BookmarksService.prototype.readBookmarks = function () {
                if (!(this.localStorageService.get(this.localstorageKey) instanceof Array)) {
                    this.localStorageService.set(this.localstorageKey, []);
                }
                var products = this.localStorageService.get(this.localstorageKey);
                //this.$log.debug("BookmarksService.readBookmarks(): Products " + products.length);
                return products;
            };
            BookmarksService.prototype.writeBookmarks = function (products) {
                this.localStorageService.set(this.localstorageKey, products);
            };
            BookmarksService.$inject = [
                'localStorageService',
                '$log',
                '$http',
                'Logger',
                'serverUrl'
            ];
            return BookmarksService;
        })();
        /**
         * @ngdoc service
         * @name home.service:CartService
         *
         * @description
         *
         */
        angular.module('home').service('BookmarksService', BookmarksService);
    })(Services = Home.Services || (Home.Services = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Services;
    (function (Services) {
        'use strict';
        // service to handle a shopping cart's data in a cookie on the client side.
        var CartService = (function () {
            function CartService($cookies, $rootScope, $log, $http, logger, serverUrl) {
                this.$cookies = $cookies;
                this.$rootScope = $rootScope;
                this.$log = $log;
                this.$http = $http;
                this.logger = logger;
                this.serverUrl = serverUrl;
                // the prefix of the cookie holding the cart-data
                this.cartCookiePrefix = 'cartCookie';
            }
            // read cartCookie into a cart-object that can be submitted to the server during checkout.
            CartService.prototype.getCart = function () {
                var products = this.readCartCookie();
                var cart = { username: "", changed: 0, products: [] };
                cart.username = this.readUserName();
                cart.changed = new Date().getTime();
                for (var i = 0; i < products.length; i++) {
                    cart.products.push(products[i]);
                }
                return cart;
            };
            // add a product to the cartCookie
            CartService.prototype.addProduct = function (product) {
                this.$log.debug("CartService.addProduct called: " + product.path);
                var products = this.readCartCookie();
                if (!this.containsProduct(products, product)) {
                    products.push({
                        path: product.path
                    });
                    this.logger.logSuccess('Diese Bildergalerie wurde zum Warenkorb hinzugefügt.', 'empty', this, true);
                }
                else {
                    this.logger.logWarning('Diese Bildergalerie ist schon im Warenkorb.', 'empty', this, true);
                    this.$log.debug("CartService.addProduct " + product.path + " is already in cart.");
                }
                this.writeCartCookie(products);
                return products;
            };
            // remove a product from the cartCookie
            CartService.prototype.removeProduct = function (product) {
                this.$log.debug("CartService.removeProduct called: " + product.path);
                var products = this.readCartCookie();
                for (var i = 0; i < products.length; i++) {
                    if (product.path == products[i].path) {
                        products.splice(i, 1);
                        this.$log.debug("CartService.removeProduct: " + product.path);
                    }
                }
                this.writeCartCookie(products); // write back cookie
                return products;
            };
            CartService.prototype.getNumberOfProducts = function () {
                var products = this.readCartCookie();
                return products.length;
            };
            CartService.prototype.emptyCart = function () {
                var cartCookieName = this.cartCookiePrefix + this.readUserName();
                this.$cookies.put(cartCookieName, []);
                this.$log.debug("CartService.emptyCart called");
            };
            CartService.prototype.checkout = function (cart) {
                this.$log.debug("CartService.checkout called");
                return this.$http.post(this.serverUrl.server + '/ws/orders', cart).then(this.handleSuccess, this.handleError('Error creating new order'));
            };
            CartService.prototype.getOrdersByUsername = function (username) {
                return this.$http.get(this.serverUrl.server + '/ws/orders/' + username).then(this.handleSuccess, this.handleError('Es gab Probleme mit dem Benutzernamen "' + username + '" beim Suchen von Orders.'));
            };
            CartService.prototype.getAllOrders = function () {
                return this.$http.get(this.serverUrl.server + '/ws/orders/').then(this.handleSuccess, this.handleError('Es gab Probleme beim Suchen von allen Orders.'));
            };
            CartService.prototype.markOrderProcessed = function (order) {
                return this.$http.put(this.serverUrl.server + '/ws/orders/' + order.ordered, order).then(this.handleSuccess, this.handleError('Es gab Probleme beim markieren der Order ' + order.ordered));
            };
            CartService.prototype.get = function () {
                return 'CartService';
            };
            // private functions
            CartService.prototype.handleSuccess = function (data) {
                return data;
            };
            CartService.prototype.handleError = function (error) {
                return function () {
                    return { success: false, message: error };
                };
            };
            CartService.prototype.containsProduct = function (array, elem) {
                var len = array.length;
                for (var i = 0; i < len; i++) {
                    if (array[i].path == elem.path) {
                        return true;
                    }
                }
                return false;
            };
            CartService.prototype.readCartCookie = function () {
                var cartCookieName = this.cartCookiePrefix + this.readUserName();
                if (!(this.$cookies.get(cartCookieName) instanceof Array)) {
                    this.$cookies.put(cartCookieName, []);
                }
                var products = this.$cookies.get(cartCookieName); // read cookie
                return products;
            };
            CartService.prototype.writeCartCookie = function (products) {
                var cartCookieName = this.cartCookiePrefix + this.readUserName();
                this.$cookies.put(cartCookieName, products);
            };
            CartService.prototype.readUserName = function () {
                var username = "";
                if (this.$rootScope.globals && this.$rootScope.globals.currentUser && this.$rootScope.globals.currentUser.username) {
                    username = this.$rootScope.globals.currentUser.username;
                }
                return username;
            };
            CartService.$inject = [
                '$cookies',
                '$rootScope',
                '$log',
                '$http',
                'Logger',
                'serverUrl'
            ];
            return CartService;
        })();
        /**
         * @ngdoc service
         * @name home.service:CartService
         *
         * @description
         *
         */
        angular.module('home').service('CartService', CartService);
    })(Services = Home.Services || (Home.Services = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var User;
(function (_User) {
    'use strict';
    var User = (function () {
        function User($http, serverUrl) {
            this.$http = $http;
            this.serverUrl = serverUrl;
        }
        User.prototype.get = function () {
            return 'User';
        };
        User.prototype.getAll = function () {
            return this.$http.get(this.serverUrl.server + '/ws/users').then(this.handleSuccess, this.handleError('Die Liste mit allen Benutzern konnten nicht gefunden werden.'));
        };
        User.prototype.getById = function (id) {
            return this.$http.get(this.serverUrl.server + '/ws/users/' + id).then(this.handleSuccess, this.handleError('Der Benutzer "' + id + '" konnte nicht gefunden werden.'));
        };
        User.prototype.getByUsername = function (username) {
            return this.$http.get(this.serverUrl.server + '/ws/users/' + username).then(this.handleSuccess, this.handleError('Der Benutzername "' + username + '" konnte nicht gefunden werden.'));
        };
        User.prototype.create = function (user) {
            return this.$http.post(this.serverUrl.server + '/api/users', user).then(this.handleSuccess, this.handleError('Fehler beim Erstellen des Benutzers.'));
        };
        User.prototype.update = function (user) {
            return this.$http.put(this.serverUrl.server + '/ws/users/' + user.id, user).then(this.handleSuccess, this.handleError('Fehler beim Anpassen des Benutzers.'));
        };
        User.prototype.delete = function (id) {
            return this.$http.delete(this.serverUrl.server + '/ws/users/' + id).then(this.handleSuccess, this.handleError('Der Benutzer "' + id + '" konnte nicht gelöscht werden.'));
        };
        User.prototype.isAdmin = function (username) {
            return this.$http.get(this.serverUrl.server + '/ws/admins/' + username).then(this.handleSuccess, this.handleError('Der Benutzername "' + username + '" konnte nicht gefunden werden.'));
        };
        User.prototype.authenticateUser = function (username, password) {
            return this.$http.post(this.serverUrl.server + '/api/authenticate', { username: username, password: password }).then(this.handleSuccess, this.handleError('Fehler beim Authentisieren des Benutzers.'));
        };
        // private functions
        User.prototype.handleSuccess = function (data) {
            return data;
        };
        User.prototype.handleError = function (error) {
            return function () {
                return { success: false, message: error };
            };
        };
        User.$inject = ['$http', 'serverUrl'];
        return User;
    })();
    /**
     * @ngdoc service
     * @name home.service:User
     *
     * @description
     *
     */
    angular.module('home').service('User', User);
})(User || (User = {}));

///<reference path='../../min.references.ts' />
var Home;
(function (Home) {
    var Services;
    (function (Services) {
        'use strict';
        var Repository = (function () {
            function Repository($log, $http, $q, $location, serverUrl) {
                this.$log = $log;
                this.$http = $http;
                this.$q = $q;
                this.$location = $location;
                this.serverUrl = serverUrl;
            }
            Repository.prototype.loadGalleryData = function () {
                var _this = this;
                var deferred = this.$q.defer();
                //var jsonfile = this.serverUrl.data + '/galleries-20250330.json';
                var jsonfile = '/json/galleries-20250330.json';
                if (!this.galleryData) {
                    this.$http.get(jsonfile).then(function (data) {
                        var gdata = [];
                        // loop through root (bergtouren, skitouren, etc.
                        angular.forEach(data.data, function (data) {
                            //console.log(data.title);
                            // loop through galleries
                            angular.forEach(data.galleries, function (galleries) {
                                //console.log(galleries);
                                if (galleries.activity) {
                                    gdata.push(galleries);
                                }
                                // loop through additional travel galleries
                                angular.forEach(galleries.galleries, function (galleries) {
                                    //console.log(galleries);
                                    if (galleries.activity) {
                                        gdata.push(galleries);
                                    }
                                });
                            });
                        });
                        _this.galleryData = gdata;
                        deferred.resolve(_this.galleryData);
                    });
                }
                else {
                    deferred.resolve(this.galleryData);
                }
                return deferred.promise;
            };
            Repository.prototype.getGalleries = function () {
                return this.galleryData;
            };
            Repository.prototype.getGallery = function () {
                return this.getGalleryFromPath(this.getGalleryId());
            };
            Repository.prototype.getGalleryFromPath = function (path) {
                var i = 0;
                if (this.galleryData) {
                    for (i; i < this.galleryData.length; i++) {
                        if (this.galleryData[i].path === path) {
                            break;
                        }
                    }
                    return this.galleryData[i];
                }
                return null;
            };
            Repository.prototype.getGalleryId = function () {
                // use gallery id's without photoswipe parameters &gid=1&pid=2
                var id = this.$location.path().indexOf('&') > 0 ? this.$location.path().slice(0, this.$location.path().indexOf('&')) : this.$location.path();
                return id;
            };
            Repository.$inject = ['$log', '$http', '$q', '$location', 'serverUrl'];
            return Repository;
        })();
        angular.module('home').service('Repository', Repository);
    })(Services = Home.Services || (Home.Services = {}));
})(Home || (Home = {}));

///<reference path='../../min.references.ts' />
var Authentication;
(function (_Authentication) {
    'use strict';
    var Authentication = (function () {
        function Authentication($http, $cookies, $rootScope, User) {
            this.$http = $http;
            this.$cookies = $cookies;
            this.$rootScope = $rootScope;
            this.User = User;
        }
        Authentication.prototype.get = function () {
            return 'Authentication';
        };
        Authentication.prototype.login = function (username, password, callback) {
            var response;
            this.User.authenticateUser(username, password).then(function (auth) {
                if (auth.data && auth.data.token) {
                    response = { success: true, token: auth.data.token };
                }
                else {
                    response = { success: false, message: 'Email oder Passwort falsch' };
                }
                callback(response);
            });
        };
        Authentication.prototype.setCredentials = function (username, token) {
            this.$rootScope.globals = {
                currentUser: {
                    username: username,
                    token: token
                }
            };
            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token; // jshint ignore:line
            this.$cookies.put('globals', this.$rootScope.globals);
        };
        Authentication.prototype.clearCredentials = function () {
            if (this.$rootScope.globals && this.$rootScope.globals.currentUser) {
                this.$cookies.remove('cartCookie' + this.$rootScope.globals.currentUser.username);
            }
            this.$rootScope.globals = {};
            this.$cookies.remove('globals');
            this.$http.defaults.headers.common.Authorization = 'Basic ';
        };
        Authentication.$inject = ['$http', '$cookies', '$rootScope', 'User'];
        return Authentication;
    })();
    /**
     * @ngdoc service
     * @name home.service:Authentication
     *
     * @description
     *
     */
    angular.module('home').service('Authentication', Authentication);
})(Authentication || (Authentication = {}));

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/userhome.tpl.html',
    '<div class="row" data-ng-cloak="">\n' +
    '  <div class="col-lg-12">\n' +
    '    <h1 class="page-header">{{vm.user.firstname}} {{vm.user.lastname}} <small>{{vm.user.username}}</small>\n' +
    '      <a href="login" class="btn btn-primary">Logout</a>\n' +
    '    </h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row profile">\n' +
    '  <div class="col-lg-12" data-ng-cloak="">\n' +
    '    <h2 class="profile-header">Meine offenen Bestellungen</h2>\n' +
    '    <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse="" data-ng-repeat="myuserorders in vm.userOrder">\n' +
    '      <div class="panel panel-default" data-ng-model="panels.activePanel" data-ng-repeat="myorder in myuserorders | filter:{processed:false}">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">{{myorder.products.length}} <span data-ng-if="myorder.products.length > 1">Bildgalerien</span><span data-ng-if="myorder.products.length < 2">Bildgalerie</span>\n' +
    '              am {{myorder.ordered | date:\'dd.MM.yyyy HH:mm:ss\'}} bestellt\n' +
    '              </a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body">\n' +
    '            <ul class="list-unstyled">\n' +
    '              <li data-ng-repeat="myproduct in myorder.products">\n' +
    '                <gallery-item path="{{myproduct.path}}"></gallery-item>\n' +
    '              </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <div class="col-lg-12" data-ng-cloak="" data-ng-if="vm.admin">\n' +
    '\n' +
    '    <h2 class="profile-header">Shop Administrator</h2>\n' +
    '\n' +
    '    <h3 class="profile-subheader">Alle pendenten Bestellungen</h3>\n' +
    '\n' +
    '      <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse="" data-ng-repeat="userorders in vm.allOrders">\n' +
    '        <div class="panel panel-default" data-ng-model="panels.activePanel" data-ng-repeat="oneorder in userorders | filter:{processed:false}">\n' +
    '          <div class="panel-heading" role="tab">\n' +
    '            <h4 class="panel-title">\n' +
    '              <a bs-collapse-toggle="">\n' +
    '                Bestellung von {{oneorder.username}} am {{oneorder.ordered | date:\'dd.MM.yyyy HH:mm:ss\' }}\n' +
    '              </a>\n' +
    '            </h4>\n' +
    '          </div>\n' +
    '          <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '            <div class="panel-body">\n' +
    '              <ul class="list-unstyled">\n' +
    '                <li data-ng-repeat="oneproduct in oneorder.products">\n' +
    '                  <gallery-item path="{{oneproduct.path}}"></gallery-item>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '              <a href="" class="btn btn btn-success" data-ng-click="vm.markOrderProcessed(oneorder)">\n' +
    '                <i class="fa fa-check-square-o fa-fw"></i> Als verarbeitet markieren</a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '    <h3 class="profile-subheader">Alle erledigten Bestellungen</h3>\n' +
    '\n' +
    '      <div class="panel-group" role="tablist" data-start-collapsed="true" aria-multiselectable="true" bs-collapse="" data-ng-repeat="userorders in vm.allOrders">\n' +
    '        <div class="panel panel-default" data-ng-model="panels.activePanel" data-ng-repeat="oneorder in userorders | filter:{processed:true} | orderBy:\'-ordered\'">\n' +
    '          <div class="panel-heading" role="tab">\n' +
    '            <h4 class="panel-title">\n' +
    '              <a bs-collapse-toggle="">\n' +
    '                Bestellung von {{oneorder.username}} am {{oneorder.ordered | date:\'dd.MM.yyyy HH:mm:ss\' }}\n' +
    '              </a>\n' +
    '            </h4>\n' +
    '          </div>\n' +
    '          <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '            <div class="panel-body">\n' +
    '              <ul class="list-unstyled">\n' +
    '                <li data-ng-repeat="oneproduct in oneorder.products">\n' +
    '                  <gallery-item path="{{oneproduct.path}}"></gallery-item>\n' +
    '                </li>\n' +
    '              </ul>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '    <h3 class="profile-subheader">Registrierte Benutzer verwalten</h3>\n' +
    '\n' +
    '    <div class="panel-group" role="tablist" data-start-collapsed="true" aria-multiselectable="true" bs-collapse="">\n' +
    '      <div class="panel panel-default" data-ng-repeat="user in vm.allUsers">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">\n' +
    '               {{user.firstname}} {{user.lastname}} ({{user.username}})\n' +
    '            </a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body">\n' +
    '            <dl class="dl-horizontal">\n' +
    '              <dt>Benutzername</dt><dd>{{user.username}}</dd>\n' +
    '              <dt>Vorname</dt><dd>{{user.firstname}}</dd>\n' +
    '              <dt>Nachname</dt><dd>{{user.lastname}}</dd>\n' +
    '            </dl>\n' +
    '            <a href="" class="btn btn btn-danger" data-ng-click="vm.deleteUser(user.id)">\n' +
    '              <i class="fa fa-trash fa-fw"></i> Benutzer löschen</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/simple-home.tpl.html',
    '<!-- Update Metatags -->\n' +
    '<view-title>bergauf.ch: What\'s New</view-title>\n' +
    '<meta data-view-head name="description" content="Outdoor Photo-Blog und Gipfelbuch zu den Themen Skitouren, Bergsteigen, MTB und Reisen" />\n' +
    '<meta data-view-head property="og:title" content="Willkommen beim Outdoor Fotoblog bergauf.ch" />\n' +
    '<meta data-view-head property="og:description" content="Outdoor Photo-Blog und Gipfelbuch zu den Themen Skitouren, Bergsteigen, MTB und Reisen." />\n' +
    '<meta data-view-head property="og:url" content="{{home.serverUrl.host}}/index" />\n' +
    '<link data-view-head rel="canonical" href="https://www.bergauf.ch/index" >\n' +
    '\n' +
    '<main>\n' +
    '<!-- Page Heading -->\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <h1 class="page-header lazy">Aktuelles / Editor\'s Choice</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/bildergalerien-nach-region/alpe-devero" title="Weiter zu den Skitouren von Crampiolo / Alpe Devero">\n' +
    '            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/crampiolo-punta-della-valle.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Skitouren von Crampiolo / Alpe Devero" href="/bildergalerien-nach-region/alpe-devero">Alpe Devero</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2025-03-15">15.03. - </time>\n' +
    '            <time datetime="2025-03-21">21.03.2025</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-region/alpe-devero">Alpe Devero</a> -\n' +
    '            <a href="bildergalerien-nach-laender/italien">IT</a>\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/bildergalerien-nach-region/medel" title="Weiter zu den Skitouren ab Curaglia / Medel">\n' +
    '            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/pizzo-dell-uomo.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Skitouren ab Curaglia / Medel" href="/bildergalerien-nach-region/medel">Medel</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2025-01-24">24.01. - </time>\n' +
    '            <time datetime="2025-01-27">27.01.2025</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-region/medel">Medel</a> -\n' +
    '            <a href="bildergalerien-nach-laender/schweiz">CH</a>\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/exped/reise-nach-marokko" title="Weiter zu den Marokko Bildergalerien">\n' +
    '            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-marokko/icons/gorges-du-dades.webp">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Marokko Bildergalerien" href="/exped/reise-nach-marokko">Marokko Reise</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2024-04-29">29.04. - </time>\n' +
    '            <time datetime="2024-03-21">21.05.2024</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            Marokko\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="/bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/bildergalerien-nach-region/matschertal" title="Weiter zu den Skitouren im Matschertal">\n' +
    '            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/matschertal-upisee.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Skitouren im Matschertal" href="/bildergalerien-nach-region/matschertal">Matschertal</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2024-03-10">10.03. - </time>\n' +
    '            <time datetime="2024-03-15">15.03.2024</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-region/matschertal">Matschertal</a> -\n' +
    '            <a href="bildergalerien-nach-laender/italien">IT</a>\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '      <section>\n' +
    '        <div class="media">\n' +
    '          <div class="media-left">\n' +
    '            <a href="/bildergalerien-nach-region/safiental" title="Weiter zu den Skitouren im Safiental">\n' +
    '              <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/safiental-baerenhorn.jpg">\n' +
    '            </a>\n' +
    '          </div>\n' +
    '          <div class="media-body">\n' +
    '            <h2 class="media-heading">\n' +
    '              <a title="Weiter zu den Skitouren im Safiental" href="/bildergalerien-nach-region/safiental">Safiental</a>\n' +
    '            </h2>\n' +
    '            <p class="media-heading media-heading--small media-heading--light">\n' +
    '              <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '              <time datetime="2024-02-02">02.02. - </time>\n' +
    '              <time datetime="2024-02-05">05.02.2024</time>\n' +
    '            </p>\n' +
    '            <div class="media-heading media-heading--small media-heading--transform">\n' +
    '              <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '              <a href="bildergalerien-nach-region/safiental">Safiental</a> -\n' +
    '              <a href="bildergalerien-nach-laender/schweiz">CH</a>\n' +
    '            </div>\n' +
    '            <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '              <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '              <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </section>\n' +
    '    </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/exped/gravelpacking-schweiz" title="Weiter zu den Gravelpacking Schweiz Velotouren">\n' +
    '            <img class="media-object" alt="Gravelbike Trails Alp Astras" height="120" width="160" src="https://www.bergauf.ch/data/exped/gravelpacking-schweiz/icons/etappe-12.webp">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Gravelpacking Schweiz Velotouren" href="/exped/gravelpacking-schweiz">Gravelpacking</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2023-09-20">20.09. - </time>\n' +
    '            <time datetime="2023-09-26">26.09.2023</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            Schweiz / Italien\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/bikepacking">Bikepacking</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/bildergalerien-nach-region/ortler-alpen" title="Weiter zu den Skitouren in den Ortler Alpen">\n' +
    '            <img class="media-object" alt="Wenig Schnee im Martelltal" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/ortler-alpen-monte-cevedale.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Skitouren in den Ortler Alpen" href="/bildergalerien-nach-region/ortler-alpen">Ortler Alpen</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2023-03-19">19.03. - </time>\n' +
    '            <time datetime="2023-03-25">25.03.2023</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-region/ortler-alpen">Ortler Alpen</a> -\n' +
    '            <a href="bildergalerien-nach-laender/italien">IT</a>\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/bildergalerien-nach-region/avers" title="Weiter zu den Skitouren ab Hotel Avers">\n' +
    '            <img class="media-object" alt="Im Aufstieg zum Wengahorn" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/avers-wengahorn.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Skitouren ab Hotel Avers" href="/bildergalerien-nach-region/avers">Avers</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2023-01-20">20.01. - </time>\n' +
    '            <time datetime="2023-01-23">23.01.2023</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-region/avers">Avers</a> -\n' +
    '            <a href="bildergalerien-nach-laender/schweiz">CH</a>\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="bildergalerien-nach-region/langtauferental" title="Weiter zu den Skitouren ab Hotel Langtaufererhof in Langtaufers">\n' +
    '            <img class="media-object" alt="Blick vom Roten Kopf auf den Bärenbartkogel" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/langtaufers-roterkopf.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Skitouren ab Hotel Langtaufererhof in Langtaufers" href="bildergalerien-nach-region/langtauferental">Langtauferental</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2022-04-03">03.04. - </time>\n' +
    '            <time datetime="2022-04-08">08.04.2022</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-region/langtauferental">Langtauferental</a> -\n' +
    '            <a href="bildergalerien-nach-laender/italien">IT</a>\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/skitouren/valle-maira-punta-sibolet" title="Weiter zu den Skitouren ab Pensione Ceaglio im Valle Maira">\n' +
    '            <img class="media-object" alt="Apero und Tourenplanung in der Pensione Ceaglio" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/punta-sibolet.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Skitouren ab Pensione Ceaglio im Valle Maira" href="/skitouren/valle-maira-punta-sibolet">Valle Maira</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2021-02-27">27.02. - </time>\n' +
    '            <time datetime="2021-03-06">06.03.2021</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-region/valle-maira">Valle Maira</a> -\n' +
    '            <a href="bildergalerien-nach-laender/italien">IT</a>\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/exped/reise-nach-kambodscha" title="Weiter zu den Kambodscha Bildergalerien">\n' +
    '            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-kambodscha/icons/angkor-grosse-runde.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Kambodscha Bildergalerien" href="/exped/reise-nach-kambodscha">Kambodscha Reise</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2019-02-17">16.02. - </time>\n' +
    '            <time datetime="2019-03-21">22.03.2019</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            Kambodscha\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="/bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/exped/reise-nach-kolumbien" title="Weiter zu den Kolumbien Bildergalerien">\n' +
    '            <img class="media-object" alt="Bemaltes Haus" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-kolumbien/icons/calanoa-amazonas.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Kolumbien Bildergalerien" href="/exped/reise-nach-kolumbien">Kolumbien Reise</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2017-07-02">30.06. - </time>\n' +
    '            <time datetime="2017-07-31">31.07.2018</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            Kolumbien\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <section>\n' +
    '      <div class="media">\n' +
    '        <div class="media-left">\n' +
    '          <a href="/exped/reise-nach-sulawesi" title="Weiter zu den Sulawesi Bildergalerien">\n' +
    '            <img class="media-object" alt="Floating House auf dem Lake Tempe" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-sulawesi/icons/lake-tempe.jpg">\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="media-body">\n' +
    '          <h2 class="media-heading">\n' +
    '            <a title="Weiter zu den Sulawesi Bildergalerien" href="/exped/reise-nach-sulawesi">Sulawesi Reise</a>\n' +
    '          </h2>\n' +
    '          <p class="media-heading media-heading--small media-heading--light">\n' +
    '            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '            <time datetime="2017-07-02">02.07. - </time>\n' +
    '            <time datetime="2017-07-31">31.07.2017</time>\n' +
    '          </p>\n' +
    '          <div class="media-heading media-heading--small media-heading--transform">\n' +
    '            <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '            Sulawesi\n' +
    '          </div>\n' +
    '          <div class="media-heading media-heading--small hidden-xs ng-scope">\n' +
    '            <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '            <a href="/bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </section>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '<!-- /.row -->\n' +
    '</main>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/searchfilters.tpl.html',
    '<div class="aside ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">\n' +
    '  <div class="aside-dialog">\n' +
    '\n' +
    '    <div class="aside-content">\n' +
    '      <div class="aside-header" data-ng-show="title">\n' +
    '        <button type="button" class="close" data-ng-click="$hide()"><i class="fa fa-times"></i></button>\n' +
    '        <h4 class="aside-title ng-binding" data-ng-bind="title">Suchen</h4>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-body">\n' +
    '        <div class="col-xs-*">\n' +
    '          <h4 class="aside-title">Anzahl Bildergalerien: <span class="label label-default">{{filteredGalleries.length}}</span></h4>\n' +
    '          <div class="form-group">\n' +
    '            <label for="activity" class="sr-only">Nach Aktivität filtern:</label>\n' +
    '            <select class="form-control" id="activity" data-ng-model="home.activity" data-ng-options="activity.id as activity.name for activity in home.activityTags" data-ng-init="home.activity=home.activity||home.activityTags[0].id" data-ng-change="home.triggerScroll();">\n' +
    '            </select>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label for="country" class="sr-only">Nach Land filtern:</label>\n' +
    '            <select class="form-control" id="country" data-ng-model="home.country" data-ng-options="country.id as country.name for country in home.countryTags" data-ng-init="home.country=home.country||home.countryTags[0].id" data-ng-change="home.triggerScroll();">\n' +
    '            </select>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label for="region" class="sr-only">Nach Region filtern:</label>\n' +
    '            <select class="form-control" id="region" data-ng-model="home.region" data-ng-options="region.id as region.name for region in home.regionTags" data-ng-init="home.region=home.region||home.regionTags[0].id" data-ng-change="home.triggerScroll();">\n' +
    '            </select>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label for="fulltext" class="sr-only">Suche:</label>\n' +
    '            <input class="form-control" type="text" placeholder="Suchen..." id="fulltext" data-ng-model="home.query" data-ng-model-options="{debounce:500}" data-ng-change="home.triggerScroll();">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-footer">\n' +
    '        <a href="suche" class="btn btn-default btn-sm" role="button">zurücksetzen</a>\n' +
    '        <button type="button" class="btn btn-default btn-sm" data-ng-click="$hide()">schliessen</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/register.tpl.html',
    '<div class="jumbotron">\n' +
    '  <div class="container">\n' +
    '    <div class="col-sm-8 col-sm-offset-2">\n' +
    '      <div class="col-md-6 col-md-offset-3">\n' +
    '        <h2>Registrieren</h2>\n' +
    '        <div class="alert alert-danger" data-ng-show="vm.error">{{vm.error}}</div>\n' +
    '        <form name="form" role="form" data-ng-submit="vm.register()">\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': form.firstname.$dirty && form.firstname.$error.required }">\n' +
    '            <label for="firstname">Vorname</label>\n' +
    '            <input type="text" name="firstname" id="firstname" class="form-control" required placeholder="Vorname eingeben" data-ng-model="vm.user.firstname">\n' +
    '            <span class="help-block" data-ng-show="form.firstname.$dirty && form.firstname.$error.required">Vorname ist ein Mussfeld</span>\n' +
    '          </div>\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': form.lastname.$dirty && form.lastname.$error.required }">\n' +
    '            <label for="lastname">Nachname</label>\n' +
    '            <input type="text" name="lastname" id="lastname" class="form-control" required placeholder="Nachname eingeben" data-ng-model="vm.user.lastname">\n' +
    '            <span class="help-block" data-ng-show="form.lastname.$dirty && form.lastname.$error.required">Nachname ist ein Mussfeld</span>\n' +
    '          </div>\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': form.username.$dirty && form.username.$error.required }">\n' +
    '            <label for="username">Email</label>\n' +
    '            <input type="email" name="username" id="username" class="form-control" required placeholder="Email eingeben" data-ng-model="vm.user.username">\n' +
    '            <span class="help-block" data-ng-show="form.username.$dirty && form.username.$error.required">Email ist ein Mussfeld</span>\n' +
    '          </div>\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': form.password.$dirty && form.password.$error.required }">\n' +
    '            <label for="password">Passwort</label>\n' +
    '            <input type="password" name="password" id="password" class="form-control" required placeholder="Passwort eingeben" data-ng-model="vm.user.password" data-ng-minlength="6">\n' +
    '            <span class="help-block" data-ng-show="form.password.$dirty && (form.password.$error.required || form.password.$invalid)">Mindestens 6-stelliges Passwort ist ein Muss</span>\n' +
    '          </div>\n' +
    '          <div class="form-actions">\n' +
    '            <button type="submit" class="btn btn-primary" data-ng-disabled="vm.dataLoading">Registrieren</button>\n' +
    '            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" data-ng-if="vm.dataLoading">\n' +
    '            <a href="login" class="btn btn-link">Abbrechen</a>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <p class="well">Vorteile mit einem Login: Bestellen Sie kostenlos hochauflösende Versionen Ihrer Lieblings-Bildergalerien (für den privaten Gebrauch).</p>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/login.tpl.html',
    '<div class="jumbotron">\n' +
    '  <div class="container">\n' +
    '    <div class="col-sm-8 col-sm-offset-2">\n' +
    '      <div class="col-md-6 col-md-offset-3">\n' +
    '        <h2>Login</h2>\n' +
    '        <div class="alert alert-danger" data-ng-show="vm.error">{{vm.error}}</div>\n' +
    '        <form name="form" role="form" data-ng-submit="vm.login()">\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': form.username.$dirty && form.username.$error.required }">\n' +
    '            <label for="username">Email</label>\n' +
    '            <input type="email" name="username" id="username" class="form-control" required placeholder="Email eingeben" data-ng-model="vm.username">\n' +
    '            <span class="help-block" data-ng-show="form.username.$dirty && form.username.$error.required && form.$invalid">Gültige Email eingeben</span>\n' +
    '          </div>\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': form.password.$dirty && form.password.$error.required }">\n' +
    '            <label for="password">Passwort</label>\n' +
    '            <input type="password" name="password" id="password" class="form-control" required placeholder="Passwort eingeben" data-ng-model="vm.password">\n' +
    '            <span class="help-block" data-ng-show="form.password.$dirty && form.password.$error.required">Passwort ist ein Mussfeld</span>\n' +
    '          </div>\n' +
    '          <div class="form-actions">\n' +
    '            <button type="submit" class="btn btn-primary" data-ng-disabled="vm.dataLoading">Login</button>\n' +
    '            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" data-ng-if="vm.dataLoading">\n' +
    '            <a href="register" class="btn btn-link">Neu Registrieren</a>\n' +
    '          </div>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <p class="well">Vorteile mit einem Login: Bestellen Sie kostenlos hochauflösende Versionen Ihrer Lieblings-Bildergalerien (für den privaten Gebrauch).</p>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/index.tpl.html',
    '<!-- Update Metatags -->\n' +
    '<view-title>{{home.title}}</view-title>\n' +
    '<meta data-view-head="" name="description" content="Übersicht zu den \'{{home.title}}\' Bildergalerien">\n' +
    '<meta data-view-head="" property="og:title" content="{{home.title}}">\n' +
    '<meta data-view-head="" property="og:description" content="Übersicht zu den \'{{home.title}}\' Bildergalerien">\n' +
    '<meta data-view-head="" property="og:image" content="{{home.serverUrl.data}}{{home.ogImage}}" data-ng-if="home.ogImage">\n' +
    '<meta data-view-head="" property="og:url" content="{{home.canonicalUrl}}">\n' +
    '<link data-view-head="" rel="canonical" href="{{home.canonicalUrl}}">\n' +
    '\n' +
    '<script type="application/ld+json" data-ng-bind="home.addBreadcrumb"></script>\n' +
    '\n' +
    '<!-- Page Heading -->\n' +
    '<main>\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <h1 class="page-header page-header-collections p-name">{{home.title}}</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<!-- /.row -->\n' +
    '<div class="row">\n' +
    '  <div data-ng-repeat="gallery in home.galleries | orderBy:home.orderby | filter:home.query | activity:home.themeFilter track by gallery.path" class="col-xs-12 col-sm-6 col-md-6 col-lg-4">\n' +
    '    <gallery-thumbnail gallery="{{gallery}}" countries="home.countries"></gallery-thumbnail>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<!-- /.row -->\n' +
    '</main>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/impressum.tpl.html',
    '<!-- Update Metatags -->\n' +
    '<view-title>bergauf.ch Impressum: Verantwortlichkeiten für Inhalt und Realisierung</view-title>\n' +
    '<meta data-view-head="" name="description" content="Verantwortlichkeiten für Inhalt, Design und Realisierung von bergauf.ch">\n' +
    '<meta data-view-head="" property="og:title" content="bergauf.ch Impressum: Verantwortlichkeiten für Inhalt und Realisierung">\n' +
    '<meta data-view-head="" property="og:description" content="Verantwortlichkeiten für Inhalt, Design und Realisierung von bergauf.ch">\n' +
    '<meta data-view-head="" property="og:url" content="{{home.serverUrl.host}}/impressum">\n' +
    '<link data-view-head="" rel="canonical" href="https://www.bergauf.ch/impressum">\n' +
    '\n' +
    '<!-- Page Heading/Breadcrumbs -->\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <h1 class="page-header lazy">Impressum</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<!-- /.row -->\n' +
    '\n' +
    '<!-- start impressum http://www.bag.ch/impressum-generator -->\n' +
    '\n' +
    '<!-- Content Row -->\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse="">\n' +
    '      <div class="panel panel-default" data-ng-model="panels.activePanel">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">Verantwortlich für den Inhalt, Design und Realisierung</a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body h-card">\n' +
    '            <p><span class="p-name">Roland Aeschimann</span><br>\n' +
    '              <span class="p-street-address">Feldeggstrasse 33</span><br>\n' +
    '              <span class="p-postal-code">8008</span> <span class="p-locality">Zürich</span><br>\n' +
    '              <span class="p-country-name">Schweiz</span></p>\n' +
    '            <p><a href="contact">info|[at]bergauf.ch</a></p>\n' +
    '            <p>Programmierung: Roland Aeschimann & Erol Ünala</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">Haftungsausschluss</a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body">\n' +
    '            <p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,\n' +
    '              Zuverlässigkeit und Vollständigkeit der Informationen.</p>\n' +
    '            <p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem\n' +
    '              Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der\n' +
    '              Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.</p>\n' +
    '            <p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das\n' +
    '              gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die\n' +
    '              Veröffentlichung zeitweise oder endgültig einzustellen.</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">Haftung für Links</a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body">\n' +
    '            <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird\n' +
    '              jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten\n' +
    '              erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">Urheberrechte</a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body">\n' +
    '            <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website\n' +
    '              gehören ausschliesslich <strong>Roland Aeschimann</strong> oder den speziell genannten Rechtsinhabern. Für\n' +
    '              die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus\n' +
    '              einzuholen.</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">Datenschutz</a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body">\n' +
    '            <p>Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen\n' +
    '              des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf\n' +
    '              Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein. Persönliche Daten\n' +
    '              werden streng vertraulich behandelt und weder an Dritte verkauft noch weiter gegeben.</p>\n' +
    '            <p>In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich\n' +
    '              vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.</p>\n' +
    '            <p>Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse, Datum,\n' +
    '              Uhrzeit, Browser-Anfrage und allg. übertragene Informationen zum Betriebssystem resp. Browser. Diese\n' +
    '              Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind,\n' +
    '              anhand derer wir unsere Angebote entsprechend verbessern können.</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="panel panel-default">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">Datenschutzerklärung für die Nutzung von Google Analytics</a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="">\n' +
    '          <div class="panel-body">\n' +
    '            <p> Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google\n' +
    '              Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine\n' +
    '              Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über\n' +
    '              Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und\n' +
    '              dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse\n' +
    '              von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des\n' +
    '              Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.</p>\n' +
    '\n' +
    '            <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort\n' +
    '              gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports\n' +
    '              über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der\n' +
    '              Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese\n' +
    '              Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit\n' +
    '              Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics von Ihrem Browser\n' +
    '              übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>\n' +
    '\n' +
    '            <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software\n' +
    '              verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche\n' +
    '              Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie\n' +
    '              sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und\n' +
    '              Weise und zu dem zuvor benannten Zweck einverstanden.</p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!-- /.col-lg-12 -->\n' +
    '</div>\n' +
    '<!-- /.row -->\n' +
    '\n' +
    '<!-- end impressum -->\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/home.tpl.html',
    '<!-- Update Metatags -->\n' +
    '<view-title>{{home.metaTitle}}</view-title>\n' +
    '<meta data-view-head="" name="description" content="{{home.metaDescription}}">\n' +
    '<meta data-view-head="" property="og:title" content="{{home.metaTitle}}">\n' +
    '<meta data-view-head="" property="og:description" content="{{home.metaDescription}}">\n' +
    '<meta data-view-head="" property="og:image" content="{{home.serverUrl.data}}{{home.ogImage}}" data-ng-if="home.ogImage">\n' +
    '<meta data-view-head="" property="og:url" content="{{home.canonicalUrl}}">\n' +
    '<link data-view-head="" rel="canonical" href="{{home.canonicalUrl}}">\n' +
    '\n' +
    '<script type="application/ld+json" data-ng-bind="home.addBreadcrumb"></script>\n' +
    '\n' +
    '<div class="row search">\n' +
    '  <div class="col-xs-9 col-sm-8 col-md-8 col-lg-8 form-group input-group-lg">\n' +
    '    <label for="search-input"><i class="fa fa-search fa-lg"></i></label>\n' +
    '    <input class="form-control search-input" type="text" placeholder="Suchen..." id="search-input" aria-label="Bildergalerie suchen" data-ng-model="home.query" data-ng-model-options="{debounce:500}" data-ng-change="home.triggerScroll();">\n' +
    '    <a id="search-clear" class="search-clear fa fa-times-circle fa-lg show" data-ng-click="search-input.$rollbackViewValue(); home.query=\'\'"></a>\n' +
    '  </div>\n' +
    '  <div class="col-xs-3 col-sm-4 col-md-4 col-lg-4">\n' +
    '    <button type="button" class="btn btn-default btn-lg btn-block" data-title="Filtern / Suchen" data-template-url="home/views/searchfilters.tpl.html" data-animation="am-slide-right" data-controller-as="home" data-bs-aside="aside" data-container="body">\n' +
    '      <i class="fa fa-sliders fa-fw fa-lg"></i><span class="hidden-xs"> Filtern</span></button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">\n' +
    '    <h1 class="search-filter" data-ng-if="home.query || home.activity || home.country  || home.region">\n' +
    '      <span class="sr-only">Alle Bildergalerien mit </span>Suchfilter:\n' +
    '      <span data-ng-if="home.activity">"{{home.activity | convertIdToProperty:home.tags:\'activities\':\'name\'}}"</span>\n' +
    '      <span data-ng-if="home.country">"{{home.country | convertIdToProperty:home.tags:\'countries\':\'name\'}}"</span>\n' +
    '      <span data-ng-if="home.region">"{{home.region | convertIdToProperty:home.tags:\'regions\':\'name\'}}"</span>\n' +
    '      {{home.query}}\n' +
    '    </h1>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">\n' +
    '    <p class="search-hits">Bildergalerien: {{filteredGalleries.length}} von {{home.galleries.length}}</p>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<main>\n' +
    '<div class="row" data-ng-switch="home.hasInfiniteScroll">\n' +
    '  <div data-infinite-scroll="home.addMoreItems()" data-infinite-scroll-distance="2" data-infinite-scroll-listen-for-event="list:filtered" data-ng-switch-when="true">\n' +
    '    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" data-ng-repeat="gallery in home.items | filter:home.query | filter:home.activity:true | filter:home.country:true  | filter:home.region:true track by gallery.path">\n' +
    '      <gallery-thumbnail gallery="{{gallery}}" data-ng-click="home.scrollToTop();"></gallery-thumbnail>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div data-ng-switch-default="">\n' +
    '    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" data-ng-repeat="gallery in filteredGalleries = (home.galleries | orderBy:\'-date\' | filter:home.query | filter:home.activity:true | filter:home.country:true  | filter:home.region:true) track by gallery.path">\n' +
    '      <gallery-thumbnail gallery="{{gallery}}"></gallery-thumbnail>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" data-ng-repeat="gallery in filteredGalleries = (home.galleries | orderBy:\'-date\' | filter:home.query | filter:home.activity:true | filter:home.country:true  | filter:home.region:true) track by gallery.path">\n' +
    '  </div>\n' +
    '</div>\n' +
    '</main>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/gallery-detail.tpl.html',
    '<!-- check for a valid gallery -->\n' +
    '<div data-ng-switch="galleryDetail.isGallery" data-ng-controller="GalleryDetailCtrl as galleryCtrl">\n' +
    '  <div data-ng-switch-when="true">\n' +
    '\n' +
    '<!-- Update Metatags -->\n' +
    '    <view-title data-ng-bind="galleryDetail.metaTitle"></view-title>\n' +
    '    <meta data-view-head="" name="description" content="{{galleryDetail.metaDescription}}">\n' +
    '    <meta data-view-head="" property="og:title" content="{{galleryDetail.metaTitle}}">\n' +
    '    <meta data-view-head="" property="og:description" content="{{galleryDetail.metaDescription}}">\n' +
    '    <meta data-view-head="" property="og:image" content="{{galleryDetail.ogImage}}" data-ng-if="galleryDetail.ogImage">\n' +
    '    <meta data-view-head="" property="og:url" content="{{galleryDetail.serverUrl.host}}{{galleryDetail.gallery.path}}">\n' +
    '    <link data-view-head="" rel="canonical" href="{{galleryDetail.canonicalUrl}}">\n' +
    '    <script type="application/ld+json" data-ng-bind="galleryDetail.addStructuredData"></script>\n' +
    '\n' +
    '<article>\n' +
    '  <h1 class="page-header">{{galleryDetail.activityHeaderExtension}} {{galleryDetail.gallery.name}} <small>{{galleryDetail.gallery.altitudeLabel}}</small></h1>\n' +
    '  <div class="bergauf-flexgallery gallery">\n' +
    '    <figure data-ng-repeat="image in galleryDetail.galleryImages">\n' +
    '      <a href="{{galleryDetail.serverUrl.data}}{{galleryDetail.gallery.path}}/{{image.filename}}" data-size="{{image.width}}x{{image.height}}" data-analytics-on="" data-analytics-event="StartSlideshow" data-analytics-category="{{galleryDetail.gallery.name}}" data-analytics-label="{{image.filename}}" aria-label="Bild Nr. {{$index+1}} in der Galerie öffnen">\n' +
    '        <img class="lazy" data-original="{{galleryDetail.serverUrl.data}}/thumbnails{{galleryDetail.gallery.path}}/{{image.filename}}" alt="" height="107" width="160">\n' +
    '      </a>\n' +
    '      <figcaption>\n' +
    '        {{image.description}} <span class="additional-semantics">{{image.alt}}</span>\n' +
    '      </figcaption>\n' +
    '    </figure>\n' +
    '  </div>\n' +
    '  <ul class="list-inline">\n' +
    '    <li data-ng-if="galleryDetail.gallery.country">\n' +
    '      <i class="fa fa-globe fa-fw fa-lg fa-color"></i>\n' +
    '      <span data-ng-if="galleryDetail.gallery.region">\n' +
    '        <a id="region" class="p-category" href="bildergalerien-nach-region/{{galleryDetail.gallery.region}}" data-analytics-on="" data-analytics-event="Tagging" data-analytics-category="Regions" data-analytics-label="{{galleryDetail.gallery.region}}" data-ng-click="galleryDetail.scrollToTop();">\n' +
    '          {{galleryDetail.gallery.region | convertIdToProperty:galleryDetail.tags:\'regions\':\'name\'}}</a>\n' +
    '      </span>\n' +
    '      <span data-ng-if="galleryDetail.gallery.region && galleryDetail.gallery.country"> &minus; </span>\n' +
    '      <span data-ng-if="galleryDetail.gallery.country">\n' +
    '        <a id="country" href="bildergalerien-nach-laender/{{galleryDetail.gallery.country}}" data-analytics-on="" data-analytics-event="Tagging" data-analytics-category="Countries" data-analytics-label="{{galleryDetail.gallery.country}}" data-ng-click="galleryDetail.scrollToTop();">\n' +
    '        {{galleryDetail.gallery.country | convertIdToProperty:galleryDetail.tags:\'countries\':\'name\'}}</a>\n' +
    '      </span>\n' +
    '    </li>\n' +
    '    <li data-ng-if="galleryDetail.gallery.date">\n' +
    '      <i class="fa fa-calendar fa-fw fa-lg fa-color"></i>\n' +
    '      <span data-ng-if="galleryDetail.gallery.dateStart"><time datetime="{{galleryDetail.gallery.dateStart}}">{{galleryDetail.gallery.dateStart | date:\'dd.MM. - \'}}</time></span>\n' +
    '      <time datetime="{{galleryDetail.gallery.date}}">{{galleryDetail.gallery.date | date:\'dd.MM.yyyy\'}}</time>\n' +
    '    </li>\n' +
    '    <li>\n' +
    '      <i class="fa fa-bookmark fa-fw fa-lg fa-color"></i>\n' +
    '        <a href="#" data-analytics-on="" data-analytics-event="AddBookmark" data-analytics-category="{{galleryDetail.gallery.name}}" data-analytics-label="{{galleryDetail.gallery.activity}}" data-ng-click="galleryCtrl.addBookmark()">merken</a>\n' +
    '    </li>\n' +
    '  </ul>\n' +
    '</article>\n' +
    '<span class="photoswipe"></span>\n' +
    '\n' +
    '<hr>\n' +
    '<aside>\n' +
    '<ul class="list-inline">\n' +
    '  <li>Verwandte Galerien</li>\n' +
    '  <li data-ng-if="galleryDetail.gallery.activity">\n' +
    '    <ul id="activities" class="list-inline list-inline_tags">\n' +
    '      <li data-ng-repeat="activity in galleryDetail.gallery.activity">\n' +
    '        <i class="fa fa-tag fa-fw fa-lg fa-color"></i>\n' +
    '        <a id="activity-{{$index+1}}" href="bildergalerien-nach-outdoor-aktivitaet/{{activity}}" data-analytics-on="" data-analytics-event="Tagging" data-analytics-category="Activities" data-analytics-label="{{activity}}" data-ng-click="galleryDetail.scrollToTop();">\n' +
    '          {{activity | convertIdToProperty:galleryDetail.tags:\'activities\':\'name\'}}</a>\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '  </li>\n' +
    '</ul>\n' +
    '</aside>\n' +
    '<!-- Root element of PhotoSwipe. Must have class pswp. -->\n' +
    '    <div id="gallery" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">\n' +
    '\n' +
    '      <!-- Background of PhotoSwipe.\n' +
    '           It\'s a separate element as animating opacity is faster than rgba(). -->\n' +
    '      <div class="pswp__bg"></div>\n' +
    '\n' +
    '      <!-- Slides wrapper with overflow:hidden. -->\n' +
    '      <div class="pswp__scroll-wrap">\n' +
    '\n' +
    '        <!-- Container that holds slides.\n' +
    '            PhotoSwipe keeps only 3 of them in the DOM to save memory.\n' +
    '            Don\'t modify these 3 pswp__item elements, data is added later on. -->\n' +
    '        <div class="pswp__container">\n' +
    '          <div class="pswp__item"></div>\n' +
    '          <div class="pswp__item"></div>\n' +
    '          <div class="pswp__item"></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->\n' +
    '        <div class="pswp__ui pswp__ui--hidden">\n' +
    '\n' +
    '          <div class="pswp__top-bar">\n' +
    '\n' +
    '            <!--  Controls are self-explanatory. Order can be changed. -->\n' +
    '\n' +
    '            <div class="pswp__counter"></div>\n' +
    '            <button class="pswp__button pswp__button--close" title="Schliessen (Esc)"></button>\n' +
    '            <button class="pswp__button pswp__button--share" title="Teilen"></button>\n' +
    '\n' +
    '            <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->\n' +
    '            <!-- element will get class pswp__preloader__active when preloader is running -->\n' +
    '            <div class="pswp__preloader">\n' +
    '              <div class="pswp__preloader__icn">\n' +
    '                <div class="pswp__preloader__cut">\n' +
    '                  <div class="pswp__preloader__donut"></div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">\n' +
    '            <div class="pswp__share-tooltip"></div>\n' +
    '          </div>\n' +
    '\n' +
    '          <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">\n' +
    '          </button>\n' +
    '\n' +
    '          <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">\n' +
    '          </button>\n' +
    '\n' +
    '          <div class="pswp__caption">\n' +
    '            <div class="pswp__caption__center"></div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '  </div>\n' +
    '  <!-- display \'gallery not found\' error for non valid url\'s -->\n' +
    '  <div data-ng-switch-default="">\n' +
    '    <meta data-view-head="" name="robots" content="noindex">\n' +
    '    <div class="jumbotron">\n' +
    '      <h1>Keine Bildergalerie gefunden.</h1>\n' +
    '      <h2>Versuche es mit einer Suche auf der Startseite.</h2>\n' +
    '      <p><a class="btn btn-primary btn-lg" href="/index" role="button">Zurück zur Startseite</a></p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/custom-search-engine.tpl.html',
    '<div class="aside aside-cart ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">\n' +
    '  <div class="aside-dialog">\n' +
    '\n' +
    '    <div class="aside-content" data-ng-controller="DefaultCtrl as home">\n' +
    '      <div class="aside-header" data-ng-show="title">\n' +
    '        <button type="button" class="close" data-ng-click="$hide()"><i class="fa fa-times"></i></button>\n' +
    '        <h4 class="aside-title ng-binding" data-ng-bind="title">Suche</h4>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-body" data-ng-cloak="">\n' +
    '        <div class="col-xs-*">\n' +
    '          <script>\n' +
    '            (function() {\n' +
    '              var cx = \'004176865626214332446:WMX480399437\';\n' +
    '              var gcse = document.createElement(\'script\');\n' +
    '              gcse.type = \'text/javascript\';\n' +
    '              gcse.async = true;\n' +
    '              gcse.src = \'https://cse.google.com/cse.js?cx=\' + cx;\n' +
    '              var s = document.getElementsByTagName(\'script\')[0];\n' +
    '              s.parentNode.insertBefore(gcse, s);\n' +
    '            })();\n' +
    '          </script>\n' +
    '          <gcse:search></gcse:search>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-footer">\n' +
    '        <button type="button" class="btn btn-default btn-sm" data-ng-click="$hide()">schliessen</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/contact.tpl.html',
    '<!-- Update Metatags -->\n' +
    '<view-title>bergauf.ch Kontakt: Wir freuen uns auf Feedback aller Art"</view-title>\n' +
    '<meta data-view-head="" name="description" content="bergauf.ch freut sich auf Feedback oder Kontaktaufnahme via Kontaktformular">\n' +
    '<meta data-view-head="" property="og:title" content="bergauf.ch Kontakt: Wir freuen uns auf Feedback aller Art">\n' +
    '<meta data-view-head="" property="og:description" content="bergauf.ch freut sich auf Feedback oder Kontaktaufnahme via Kontaktformular">\n' +
    '<meta data-view-head="" property="og:url" content="{{contact.serverUrl.host}}/contact">\n' +
    '<link data-view-head="" rel="canonical" href="{{contact.canonicalUrl}}">\n' +
    '\n' +
    '<!-- Page Heading/Breadcrumbs -->\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <h1 class="page-header lazy">Kontakt</h1>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<!-- /.row -->\n' +
    '\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-lg-12">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Sende uns eine Nachricht (* Pflichtfelder)</h2>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="panel-body">\n' +
    '        <form name="contactform" method="post" action="" class="form-horizontal" role="form" novalidate="" data-ng-submit="submit(contactform, $event)">\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': contactform.inputName.$invalid && submitted }">\n' +
    '            <label for="inputName" class="col-lg-2 control-label">Name *</label>\n' +
    '            <div class="col-lg-10">\n' +
    '              <input type="text" class="form-control" id="inputName" name="inputName" placeholder="Name eingeben..." required aria-required="true" data-ng-model="formData.inputName">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': contactform.inputEmail.$invalid && submitted }">\n' +
    '            <label for="inputEmail" class="col-lg-2 control-label">Email *</label>\n' +
    '            <div class="col-lg-10">\n' +
    '              <input type="email" class="form-control" id="inputEmail" name="inputEmail" placeholder="Email eingeben..." required aria-required="true" data-ng-model="formData.inputEmail">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': contactform.inputSubject.$invalid && submitted }">\n' +
    '            <label for="inputSubject" class="col-lg-2 control-label">Betreff *</label>\n' +
    '            <div class="col-lg-10">\n' +
    '              <input type="text" class="form-control" id="inputSubject" name="inputSubject" placeholder="Betreff eingeben..." required aria-required="true" data-ng-model="formData.inputSubject">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group" data-ng-class="{ \'has-error\': contactform.inputMessage.$invalid && submitted }">\n' +
    '            <label for="inputMessage" class="col-lg-2 control-label">Nachricht *</label>\n' +
    '            <div class="col-lg-10">\n' +
    '              <textarea class="form-control" rows="4" id="inputMessage" name="inputMessage" placeholder="Nachricht eingeben..." required aria-required="true" data-ng-model="formData.inputMessage"></textarea>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="form-group">\n' +
    '            <div class="col-lg-offset-2 col-lg-10">\n' +
    '              <button type="submit" class="btn btn-primary" data-ng-disabled="submitButtonDisabled">Nachricht senden</button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </form>\n' +
    '\n' +
    '        <p style="padding: 15px; margin: 0;" data-ng-class="result">{{ resultMessage }}</p>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/cart.tpl.html',
    '<div class="aside aside-cart ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">\n' +
    '  <div class="aside-dialog">\n' +
    '\n' +
    '    <div class="aside-content" data-ng-controller="CartCtrl as cart">\n' +
    '      <div class="aside-header" data-ng-show="title">\n' +
    '        <button type="button" class="close" data-ng-click="$hide()"><i class="fa fa-times"></i></button>\n' +
    '        <h4 class="aside-title ng-binding" data-ng-bind="title">Dein Warenkorb</h4>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-body" data-ng-cloak="">\n' +
    '        <div class="col-xs-*">\n' +
    '          <p><i class="fa fa-user fa-lg"></i> {{cart.user.username}}</p>\n' +
    '          <ul class="list-unstyled">\n' +
    '            <li class="cart-item" data-ng-repeat="product in cart.products">\n' +
    '              <a href="" data-ng-click="cart.deleteProduct(product);"><i class="fa fa-trash"></i></a>\n' +
    '              <gallery-item path="{{product.path}}"></gallery-item>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '        <div class="col-xs-* text-right">\n' +
    '          <a href="" class="btn btn-primary" data-ng-disabled="vm.dataLoading || cart.products.length <1" data-ng-click="cart.checkout();$hide()">Checkout</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-footer">\n' +
    '        <button type="button" class="btn btn-default btn-sm" data-ng-click="$hide()">schliessen</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/views/bookmarks.tpl.html',
    '<div class="aside aside-cart ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">\n' +
    '  <div class="aside-dialog">\n' +
    '\n' +
    '    <div class="aside-content" data-ng-controller="BookmarksCtrl as fav">\n' +
    '      <div class="aside-header" data-ng-show="title">\n' +
    '        <button type="button" class="close" data-ng-click="$hide()"><i class="fa fa-times"></i></button>\n' +
    '        <h4 class="aside-title ng-binding" data-ng-bind="title">Meine Favoriten</h4>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-body" data-ng-cloak="">\n' +
    '        <div class="col-xs-*">\n' +
    '          <ul class="list-unstyled">\n' +
    '            <li class="cart-item" data-ng-repeat="product in fav.products">\n' +
    '              <a href="" data-ng-click="fav.deleteProduct(product);"><i class="fa fa-trash"></i></a>\n' +
    '              <gallery-item path="{{product.path}}" data-ng-click="$hide()"></gallery-item>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="aside-footer">\n' +
    '        <button type="button" class="btn btn-default btn-sm" data-ng-click="$hide()">schliessen</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/directives/swisstopo-map-directive.tpl.html',
    '<!-- Content Row for swisstopo maps-->\n' +
    '<div class="row" data-ng-if="galleryDetail.gallery.map">\n' +
    '  <div class="col-lg-12">\n' +
    '    <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse="">\n' +
    '\n' +
    '      <div class="panel panel-default" data-ng-model="panels.activePanel">\n' +
    '        <div class="panel-heading" role="tab">\n' +
    '          <h4 class="panel-title">\n' +
    '            <a bs-collapse-toggle="">swisstopo Karte {{galleryDetail.gallery.name}}<span data-ng-if="galleryDetail.gallery.region"> - <a href="bildergalerien-nach-region/{{galleryDetail.gallery.region}}">\n' +
    '              {{galleryDetail.gallery.region | convertIdToProperty:galleryDetail.tags:\'regions\':\'name\'}}</a></span></a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '        <div class="panel-collapse" role="tabpanel" bs-collapse-target="" data-mapx="{{galleryDetail.gallery.map.x}}" data-mapy="{{galleryDetail.gallery.map.y}}" data-mapzoom="{{galleryDetail.gallery.map.zoom}}">\n' +
    '          <div class="panel-body">\n' +
    '            <!--script src="http://api3.geo.admin.ch/loader.js?lang=en&mode=debug" type="text/javascript"></script-->\n' +
    '            <div id="map" class="map"></div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- /.col-lg-12 -->\n' +
    '</div>\n' +
    '</div>');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/directives/gallery-thumbnail-directive.tpl.html',
    '<section>\n' +
    '  <div class="media">\n' +
    '    <div class="media-left">\n' +
    '      <a href="{{galleryObj.path}}" title="Weiter zu der {{galleryObj.name}} Bildergalerie" data-analytics-on="" data-analytics-event="GoToGallery" data-analytics-category="{{galleryObj.name}}" data-analytics-label="FromIndex" data-ng-cloak="">\n' +
    '        <img class="lazy media-object" data-original="{{serverUrl.data}}{{galleryObj.icon}}" alt="" height="120" width="160">\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="media-body">\n' +
    '      <h1 class="media-heading">\n' +
    '        <a title="Weiter zu der {{galleryObj.name}} Bildergalerie" href="{{galleryObj.path}}" data-analytics-on="" data-analytics-event="GoToGallery" data-analytics-category="{{galleryObj.name}}" data-analytics-label="FromIndex"><span data-ng-if="galleryObj.navTitle">{{galleryObj.navTitle}}</span><span data-ng-if="!galleryObj.navTitle">{{galleryObj.name}}</span>\n' +
    '        </a>\n' +
    '      </h1>\n' +
    '      <p class="media-heading media-heading--small media-heading--light" data-ng-if="galleryObj.date"><i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>\n' +
    '        <span data-ng-if="galleryObj.dateStart"><time datetime="{{galleryObj.dateStart}}">{{galleryObj.dateStart | date:\'dd.MM. - \'}}</time></span>\n' +
    '        <time datetime="{{galleryObj.date}}">{{galleryObj.date | date:\'dd.MM.yyyy\'}}</time>\n' +
    '      </p>\n' +
    '      <h2 class="media-heading media-heading--small media-heading--transform">\n' +
    '        <i class="fa fa-globe fa-fw fa-color"></i>\n' +
    '        <span data-ng-if="galleryObj.region">\n' +
    '            {{galleryObj.region | convertIdToProperty:tagsObj:\'regions\':\'name\'}}\n' +
    '        </span>\n' +
    '        <span data-ng-if="galleryObj.region && galleryObj.country"> &minus; </span>\n' +
    '        <span data-ng-if="galleryObj.country">\n' +
    '          {{galleryObj.country | convertIdToProperty:tagsObj:\'countries\':\'code\'}}\n' +
    '        </span>\n' +
    '      </h2>\n' +
    '      <span data-ng-repeat="activity in galleryObj.activity">\n' +
    '              <h2 class="media-heading media-heading--small hidden-xs" data-ng-if="galleryObj.activity && $index <2">\n' +
    '          <i class="fa fa-tags fa-fw fa-color"></i>\n' +
    '             {{activity | convertIdToProperty:tagsObj:\'activities\':\'name\'}}\n' +
    '              </h2>\n' +
    '        </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</section>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/directives/gallery-item-directive.tpl.html',
    '\n' +
    '<a href="{{galleryObj.path}}" data-analytics-on="" data-analytics-event="GoToGallery" data-analytics-category="{{galleryObj.name}}" data-analytics-label="FromBookmarks" data-ng-cloak="">\n' +
    '  <img alt="" height="60" width="80" data-ng-src="{{serverUrl.data}}{{galleryObj.icon}}">\n' +
    '</a>\n' +
    '<a href="{{galleryObj.path}}" data-analytics-on="" data-analytics-event="GoToGallery" data-analytics-category="{{galleryObj.name}}" data-analytics-label="FromBookmarks" data-ng-cloak="">\n' +
    '  {{galleryObj.name}}\n' +
    '</a>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('yandex_ddabd56063b034e0.html',
    '<html>\n' +
    '<head>\n' +
    '  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\n' +
    '</head>\n' +
    '<body>Verification: ddabd56063b034e0</body>\n' +
    '</html>\n' +
    '');
}]);

angular.module('bergauf').run(['$templateCache', function($templateCache) {
  $templateCache.put('google9b78c3dc094d7b0c.html',
    'google-site-verification: google9b78c3dc094d7b0c.html\n' +
    '');
}]);
