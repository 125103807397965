<!-- Update Metatags -->
<view-title>bergauf.ch: What's New</view-title>
<meta data-view-head name="description" content="Outdoor Photo-Blog und Gipfelbuch zu den Themen Skitouren, Bergsteigen, MTB und Reisen" />
<meta data-view-head property="og:title" content="Willkommen beim Outdoor Fotoblog bergauf.ch" />
<meta data-view-head property="og:description" content="Outdoor Photo-Blog und Gipfelbuch zu den Themen Skitouren, Bergsteigen, MTB und Reisen." />
<meta data-view-head property="og:url" content="{{home.serverUrl.host}}/index" />
<link data-view-head rel="canonical" href="https://www.bergauf.ch/index" >

<main>
<!-- Page Heading -->
<div class="row">
  <div class="col-lg-12">
    <h1 class="page-header lazy">Aktuelles / Editor's Choice</h1>
  </div>
</div>

<div class="row">

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/bildergalerien-nach-region/alpe-devero" title="Weiter zu den Skitouren von Crampiolo / Alpe Devero">
            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/crampiolo-punta-della-valle.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Skitouren von Crampiolo / Alpe Devero" href="/bildergalerien-nach-region/alpe-devero">Alpe Devero</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2025-03-15">15.03. - </time>
            <time datetime="2025-03-21">21.03.2025</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            <a href="bildergalerien-nach-region/alpe-devero">Alpe Devero</a> -
            <a href="bildergalerien-nach-laender/italien">IT</a>
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/bildergalerien-nach-region/medel" title="Weiter zu den Skitouren ab Curaglia / Medel">
            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/pizzo-dell-uomo.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Skitouren ab Curaglia / Medel" href="/bildergalerien-nach-region/medel">Medel</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2025-01-24">24.01. - </time>
            <time datetime="2025-01-27">27.01.2025</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            <a href="bildergalerien-nach-region/medel">Medel</a> -
            <a href="bildergalerien-nach-laender/schweiz">CH</a>
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
          </div>
        </div>
      </div>
    </section>
  </div>



  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/exped/reise-nach-marokko" title="Weiter zu den Marokko Bildergalerien">
            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-marokko/icons/gorges-du-dades.webp">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Marokko Bildergalerien" href="/exped/reise-nach-marokko">Marokko Reise</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2024-04-29">29.04. - </time>
            <time datetime="2024-03-21">21.05.2024</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            Marokko
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="/bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/bildergalerien-nach-region/matschertal" title="Weiter zu den Skitouren im Matschertal">
            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/matschertal-upisee.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Skitouren im Matschertal" href="/bildergalerien-nach-region/matschertal">Matschertal</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2024-03-10">10.03. - </time>
            <time datetime="2024-03-15">15.03.2024</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            <a href="bildergalerien-nach-region/matschertal">Matschertal</a> -
            <a href="bildergalerien-nach-laender/italien">IT</a>
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <section>
        <div class="media">
          <div class="media-left">
            <a href="/bildergalerien-nach-region/safiental" title="Weiter zu den Skitouren im Safiental">
              <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/safiental-baerenhorn.jpg">
            </a>
          </div>
          <div class="media-body">
            <h2 class="media-heading">
              <a title="Weiter zu den Skitouren im Safiental" href="/bildergalerien-nach-region/safiental">Safiental</a>
            </h2>
            <p class="media-heading media-heading--small media-heading--light">
              <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
              <time datetime="2024-02-02">02.02. - </time>
              <time datetime="2024-02-05">05.02.2024</time>
            </p>
            <div class="media-heading media-heading--small media-heading--transform">
              <i class="fa fa-globe fa-fw fa-color"></i>
              <a href="bildergalerien-nach-region/safiental">Safiental</a> -
              <a href="bildergalerien-nach-laender/schweiz">CH</a>
            </div>
            <div class="media-heading media-heading--small hidden-xs ng-scope">
              <i class="fa fa-tags fa-fw fa-color"></i>
              <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
            </div>
          </div>
        </div>
      </section>
    </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/exped/gravelpacking-schweiz" title="Weiter zu den Gravelpacking Schweiz Velotouren">
            <img class="media-object" alt="Gravelbike Trails Alp Astras" height="120" width="160" src="https://www.bergauf.ch/data/exped/gravelpacking-schweiz/icons/etappe-12.webp">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Gravelpacking Schweiz Velotouren" href="/exped/gravelpacking-schweiz">Gravelpacking</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2023-09-20">20.09. - </time>
            <time datetime="2023-09-26">26.09.2023</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            Schweiz / Italien
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/bikepacking">Bikepacking</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/bildergalerien-nach-region/ortler-alpen" title="Weiter zu den Skitouren in den Ortler Alpen">
            <img class="media-object" alt="Wenig Schnee im Martelltal" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/ortler-alpen-monte-cevedale.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Skitouren in den Ortler Alpen" href="/bildergalerien-nach-region/ortler-alpen">Ortler Alpen</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2023-03-19">19.03. - </time>
            <time datetime="2023-03-25">25.03.2023</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            <a href="bildergalerien-nach-region/ortler-alpen">Ortler Alpen</a> -
            <a href="bildergalerien-nach-laender/italien">IT</a>
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/bildergalerien-nach-region/avers" title="Weiter zu den Skitouren ab Hotel Avers">
            <img class="media-object" alt="Im Aufstieg zum Wengahorn" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/avers-wengahorn.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Skitouren ab Hotel Avers" href="/bildergalerien-nach-region/avers">Avers</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2023-01-20">20.01. - </time>
            <time datetime="2023-01-23">23.01.2023</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            <a href="bildergalerien-nach-region/avers">Avers</a> -
            <a href="bildergalerien-nach-laender/schweiz">CH</a>
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="bildergalerien-nach-region/langtauferental" title="Weiter zu den Skitouren ab Hotel Langtaufererhof in Langtaufers">
            <img class="media-object" alt="Blick vom Roten Kopf auf den Bärenbartkogel" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/langtaufers-roterkopf.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Skitouren ab Hotel Langtaufererhof in Langtaufers" href="bildergalerien-nach-region/langtauferental">Langtauferental</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2022-04-03">03.04. - </time>
            <time datetime="2022-04-08">08.04.2022</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            <a href="bildergalerien-nach-region/langtauferental">Langtauferental</a> -
            <a href="bildergalerien-nach-laender/italien">IT</a>
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/skitouren/valle-maira-punta-sibolet" title="Weiter zu den Skitouren ab Pensione Ceaglio im Valle Maira">
            <img class="media-object" alt="Apero und Tourenplanung in der Pensione Ceaglio" height="120" width="160" src="https://www.bergauf.ch/data/skitouren/icons/punta-sibolet.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Skitouren ab Pensione Ceaglio im Valle Maira" href="/skitouren/valle-maira-punta-sibolet">Valle Maira</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2021-02-27">27.02. - </time>
            <time datetime="2021-03-06">06.03.2021</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            <a href="bildergalerien-nach-region/valle-maira">Valle Maira</a> -
            <a href="bildergalerien-nach-laender/italien">IT</a>
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/skitour">Skitour</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/exped/reise-nach-kambodscha" title="Weiter zu den Kambodscha Bildergalerien">
            <img class="media-object" alt="" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-kambodscha/icons/angkor-grosse-runde.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Kambodscha Bildergalerien" href="/exped/reise-nach-kambodscha">Kambodscha Reise</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2019-02-17">16.02. - </time>
            <time datetime="2019-03-21">22.03.2019</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            Kambodscha
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="/bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/exped/reise-nach-kolumbien" title="Weiter zu den Kolumbien Bildergalerien">
            <img class="media-object" alt="Bemaltes Haus" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-kolumbien/icons/calanoa-amazonas.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Kolumbien Bildergalerien" href="/exped/reise-nach-kolumbien">Kolumbien Reise</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2017-07-02">30.06. - </time>
            <time datetime="2017-07-31">31.07.2018</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            Kolumbien
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <section>
      <div class="media">
        <div class="media-left">
          <a href="/exped/reise-nach-sulawesi" title="Weiter zu den Sulawesi Bildergalerien">
            <img class="media-object" alt="Floating House auf dem Lake Tempe" height="120" width="160" src="https://www.bergauf.ch/data/exped/reise-nach-sulawesi/icons/lake-tempe.jpg">
          </a>
        </div>
        <div class="media-body">
          <h2 class="media-heading">
            <a title="Weiter zu den Sulawesi Bildergalerien" href="/exped/reise-nach-sulawesi">Sulawesi Reise</a>
          </h2>
          <p class="media-heading media-heading--small media-heading--light">
            <i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
            <time datetime="2017-07-02">02.07. - </time>
            <time datetime="2017-07-31">31.07.2017</time>
          </p>
          <div class="media-heading media-heading--small media-heading--transform">
            <i class="fa fa-globe fa-fw fa-color"></i>
            Sulawesi
          </div>
          <div class="media-heading media-heading--small hidden-xs ng-scope">
            <i class="fa fa-tags fa-fw fa-color"></i>
            <a href="/bildergalerien-nach-outdoor-aktivitaet/reise">Reise</a>
          </div>
        </div>
      </div>
    </section>
  </div>

</div>
<!-- /.row -->
</main>
